import vueCookies from 'vue-cookies'; // cookies

export const SegmentsTracker = function (name, data) {
  if (typeof analytics === 'object') {
    analytics.track(name, data);
  }
};

export const SegmentsIdentify = function (email) {
  if (typeof analytics === 'object') {
    email = email || document.body.getAttribute('data-identify');
    if (email !== '') {
      if (document.body.getAttribute('data-segment-allways-fire') === 'True') {
        analytics.identify(email, {});
      }

      if (document.body.getAttribute('data-segment-use-cookie') === 'True' && !vueCookies.get('sent-segment-identify')) {
        analytics.identify(email, {});
        vueCookies.set('sent-segment-identify', 'true', '1d');
      }
    }
  }
};
