/**
Gets all the relevant information about the user
 **/

import Api from '@/Scripts/api/api';

const state = {
  userInformation: {},
  userLoading: false
};

const getters = {};

const actions = {
  async getUserInformation ({ commit }) {
    commit('setUserLoading', true);
    try {
      const response = await Api.get('/api/user/information');
      commit('setUserInformation', response.data);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setUserLoading', false);
    }
  }
};

const mutations = {
  setUserInformation (state, value) {
    state.userInformation = value;
  },
  setUserLoading (state, value) {
    state.userLoading = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
