/**
 * The module responsible for handling app-wide context state that is
 * interesting for several components that otherwise doesn't share state.
 */

const state = {
  staticHeader: false,
  hideHeader: false,
  fadeHeader: false,
  b2bHeader: false,
  b2bUserBar: false,
  b2bRoles: null,
  scrollLocked: false,
  blackTheme: false,
  blackestTheme: false,
  transparentHeader: '',
  isScrollTop: true,
  signUpBar: false,
  isLoggedIn: false,
  isLoyalty: false,
  hiddenNugde: false,
  cartName: 'default',
  site: 'b2c',
  headerCallCart: true,
  loadedModules: [],
  packShotMode: false
};

const getters = {};

const actions = {
  addLazyModules ({ commit }, value) {
    commit('addLazyModules', value);
  },

  setSite ({ commit }, value) {
    if (value === 'b2b') {
      commit('b2bHeader', true);
      commit('b2bUserBar', true);
      commit('staticHeader', true);
    } else {
      commit('b2bHeader', false);
      commit('b2bUserBar', false);
      commit('staticHeader', false);
    }
    commit('site', value);
  },

  setHeaderCallCart ({ commit }, value) {
    commit('headerCallCart', value);
  },
  setB2bUserBar ({ commit }, value) {
    commit('b2bUserBar', value);
  },
  setB2bRoles ({ commit }, value) {
    commit('b2bRoles', value);
  },
  setStaticHeader ({ commit }, value) {
    commit('staticHeader', value);
  },
  setB2bHeader ({ commit }, value) {
    commit('b2bHeader', value);
    commit('staticHeader', value);
  },
  changeCartName ({ commit }, value) {
    commit('setCartName', value);
  },
  setBlackTheme ({ commit }, value) {
    commit('themeBlack', value);
  },
  setBlackestTheme ({ commit }, value) {
    commit('themeBlackest', value);
  },
  setTransparentHeader ({ commit }, value) {
    commit('transparentHeader', value);
  },
  setHiddenLoginNugde ({ commit }) {
    commit('hideNugde', true);
  },
  setHideHeader ({ commit }, value) {
    if (!(document.getElementsByTagName('body')[0].classList.contains('-openmenu') && value)) {
      commit('hideHeader', value);
    }
  },
  setFadeHeader ({ commit }, value) {
    if (!(document.getElementsByTagName('body')[0].classList.contains('-openmenu') && value)) {
      commit('fadeHeader', value);
    }
  },
  setScrollLock ({ commit }, value) {
    commit('scrollLock', value);
  },
  setIsScrollTop ({ commit }, value) {
    commit('isScrollTop', value);
  },
  setSignUpBar ({ commit }, value) {
    commit('signUpBar', value);
  },
  setIsLoggedIn ({ commit }, value) {
    commit('isLoggedIn', value);
  },
  setIsLoyalty ({ commit }, value) {
    commit('isLoyalty', value);
  },
  setPackShotMode ({ commit }, value) {
    commit('packShotMode', value);
  }
};

const mutations = {
  addLazyModules (state, data) {
    state.loadedModules.push(data);
  },
  site (state, data) {
    state.site = data;
  },
  headerCallCart (state, data) {
    state.headerCallCart = data;
  },
  staticHeader (state, data) {
    state.staticHeader = data;
  },
  b2bHeader (state, data) {
    state.b2bHeader = data;
  },
  b2bUserBar (state, data) {
    state.b2bUserBar = data;
  },
  b2bRoles (state, data) {
    state.b2bRoles = data;
  },
  setCartName (state, data) {
    state.cartName = data;
  },
  hideNugde (state, data) {
    state.hiddenNugde = data;
  },
  hideHeader (state, data) {
    state.hideHeader = data;
  },
  fadeHeader (state, data) {
    state.fadeHeader = data;
  },
  isScrollTop (state, data) {
    state.isScrollTop = data;
  },
  signUpBar (state, data) {
    state.signUpBar = data;
  },
  scrollLock (state, data) {
    state.scrollLocked = data;
  },
  themeBlack (state, data) {
    state.blackTheme = data;
  },
  themeBlackest (state, data) {
    state.blackestTheme = data;
  },
  transparentHeader (state, data) {
    state.transparentHeader = data;
  },
  isLoggedIn (state, data) {
    state.isLoggedIn = data;
  },
  isLoyalty (state, data) {
    state.isLoyalty = data;
  },
  packShotMode (state, data) {
    state.packShotMode = data;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
