function fireCartEvent (eventName, action, products, currency) {
  const ecommerce = {
    currencyCode: currency
  };

  ecommerce[action] = {
    products
  };

  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ecommerce
    });
  }
};

function fireEvent (eventObject) {
  if (window.dataLayer) {
    window.dataLayer.push(eventObject);
  }
}

export const gaCartAdd = function (ProductObject, listName = '') {
  const quantity = 1;
  fireCartEvent('addToCart', 'add', [{
    id: ProductObject.productId,
    name: ProductObject.name.replace('\'', ''),
    price: ProductObject.price,
    brand: ProductObject.brand,
    variant: `${ProductObject.variant} ${ProductObject.size}`,
    category: ProductObject.category,
    quantity: quantity
  }], ProductObject.currency);

  // GA 4
  addToCartGa4(ProductObject, listName);
};

export const gaCartRemove = function (ProductObject, quantity) {
  fireCartEvent('removeFromCart', 'remove', [{
    id: ProductObject.productId,
    name: ProductObject.name.replace('\'', ''),
    price: ProductObject.price,
    brand: ProductObject.brand,
    variant: `${ProductObject.variant} ${ProductObject.size}`,
    category: ProductObject.category || '',
    quantity: quantity
  }], ProductObject.currency);

  // ga4
  removeFromCartGa4(ProductObject);
};

export const gaProductViewed = function (ProductObject) {
  fireEvent({
    event: 'EEProductView',
    currencyCode: ProductObject.currency,
    ecommerce: {
      detail: {
        products: [{
          id: ProductObject.productId,
          name: ProductObject.name.replace('\'', ''),
          price: ProductObject.price,
          brand: ProductObject.brand,
          variant: ProductObject.variant,
          category: ProductObject.category
        }]
      }
    }
  });

  // ga 4
  viewItemGa4(ProductObject);
};

export const gaWishlistAdd = function (ProductObject) {
  // GA 4
  addToWishlistGa4(ProductObject);
};

export const productClick = function (ProductObject, listName) {
  // GA 4
  productClickGa4(ProductObject, listName);
};

export const trackImpressions = function (ProductObjects, listName) {
  // GA 4
  trackImpressionsGa4(ProductObjects, listName);
};

export const gaLoyaltyRegistrationStarted = function () {
  fireEvent({
    event: 'GAEvent',
    eventCategory: 'Loyalty register',
    eventAction: 'Started'
  });
};

export const gaLoyaltyRegistrationCompleted = function () {
  fireEvent({
    event: 'GAEvent',
    eventCategory: 'Loyalty register',
    eventAction: 'Completed'
  });
};

export const gaCustomEvents = function (category, action, label, value = null) {
  fireEvent({
    event: 'GAEvent',
    eventCategory: category,
    eventLabel: label,
    eventAction: action,
    eventValue: value
  });
};

export const gaLoginNugde = function (label, action) {
  fireEvent({
    event: 'GAEvent',
    eventCategory: 'AccountNudge',
    eventLabel: label,
    eventAction: action
  });
};

export const gaProductListGroupNavigate = function () {
  fireEvent({
    event: 'GAEvent',
    eventCategory: 'ProductList',
    eventLabel: 'InlineLink',
    eventAction: 'click'
  });
};

export const gaProductListShowAllColors = function (label) {
  fireEvent({
    event: 'GAEvent',
    eventCategory: 'productlist groups',
    eventLabel: label,
    eventAction: 'showAllColors'
  });
};

// GA 4 Specific
const events = {
  addToCart: 'add_to_cart',
  addToWishlist: 'add_to_wishlist',
  removeFromCart: 'remove_from_cart',
  viewItem: 'view_item',
  impressions: 'view_item_list',
  checkout: 'begin_checkout', // Done with backend (frontend)
  purchase: 'transaction', // done with backend (frontend)
  productClick: 'select_item'
};

const clearCommerce = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: undefined,
      item_list_id: undefined,
      item_list_name: undefined
    });
  }
};

const addToCartGa4 = (product, listName = '') => {
  if (window.dataLayer) {
    clearCommerce();
    window.dataLayer.push({
      ecommerce: {
        currency: product.currency,
        value: product.fullPrice - product.price,
        items: [mapToGa4Product(product, listName)]
      }
    });

    window.dataLayer.push({
      event: events.addToCart
    });
  }
};

const removeFromCartGa4 = (product) => {
  if (window.dataLayer) {
    clearCommerce();
    window.dataLayer.push({
      ecommerce: {
        currency: product.currency,
        items: [mapToGa4Product(product)]
      }
    });
    window.dataLayer.push({
      event: events.removeFromCart
    });
  }
};

const addToWishlistGa4 = (product) => {
  if (window.dataLayer) {
    clearCommerce();
    window.dataLayer.push({
      ecommerce: {
        currency: product.currency,
        value: product.fullPrice - product.price,
        items: [mapToGa4Product(product)]
      }
    });

    window.dataLayer.push({
      event: events.addToWishlist
    });
  }
};

const viewItemGa4 = (product) => {
  if (!product) {
    return;
  }

  if (window.dataLayer) {
    clearCommerce();
    window.dataLayer.push({
      ecommerce: {
        currency: product.currency,
        items: [mapToGa4Product(product)]
      }
    });
    window.dataLayer.push({
      event: events.viewItem
    });
  };
};

const productClickGa4 = (product, listName) => {
  if (window.dataLayer) {
    clearCommerce();
    window.dataLayer.push({
      ecommerce: {
        currency: product.currency,
        items: [mapToGa4Product(product, listName)]
      }
    });
    window.dataLayer.push({
      event: events.productClick
    });
  }
};

const trackImpressionsGa4 = (products, listName) => {
  if (!products || products.length === 0) {
    return;
  }

  const gaProducts = mapToGa4Products(products, listName);
  if (gaProducts != null && gaProducts.length > 0 && window.dataLayer) {
    clearCommerce();
    window.dataLayer.push({
      event: events.impressions,
      item_list_id: generateListId(listName),
      item_list_name: listName,
      ecommerce: {
        currency: gaProducts[0].currency,
        items: gaProducts
      }
    });
  }
};

const generateListId = (listName) => {
  return listName.toLowerCase().split(':').join('').split(' ').join('_').split('/').join('_');
};

const mapToGa4Product = (product, listName = '') => {
  const obj = {
    item_name: `${product.categoryCollection} ${product.name ? product.name.replace('\'', '') : ''}`,
    item_id: product.productId,
    currency: product.currency,
    discount: product.fullPrice - product.price,
    price: product.fullPrice,
    item_brand: product.categoryCollection,
    item_category: product.category,
    item_variant: `${product.variant} ${product.size}`,
    quantity: product.quantity != null ? product.quantity : 1
  };
  if (product.index > -1) {
    obj.index = product.index;
  }

  if (listName != null && listName !== '') {
    obj.item_list_name = listName;
    obj.item_list_id = generateListId(listName);
  }

  if (typeof product.categories !== 'undefined' && product.categories.length > 0) {
    let first = true;
    for (let i = 0; i < product.categories.length; i++) {
      if (first) {
        obj.category = product.categories[i];
        first = false;
      } else {
        obj[`category${i + 1}`] = product.categories[i];
      }
    }
  }
  return obj;
};

const mapToGa4Products = (products, listName = '') => {
  return products.map((product) => mapToGa4Product(product, listName));
};

export const CustomEventsGa4 = function (eventName, customData) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...customData
    });
  }
};
