function getURLParameter (key) {
  const vArr = window.location.search.substring(1).split('&');

  const vObj = {};
  for (let i = 0; i < vArr.length; i++) {
    const v = vArr[i].split('=');
    vObj[v[0]] = v[1];
  }
  return vObj[key];
}

function updateUrlParameter (uri, key, value) {
  const i = uri.indexOf('#');
  const hash = i === -1 ? '' : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    uri = uri.replace(re, `$1${key}=${encodeURIComponent(value)}$2`);
  } else {
    uri = `${uri}${separator}${key}=${encodeURIComponent(value)}`;
  }
  return `${uri}${hash}`; // finally append the hash as well
}

function updateUrlHash (uri, key, value) {
  const i = uri.indexOf('#');
  const baseUrl = i === -1 ? uri : uri.substr(0, i);
  return `${baseUrl}${key}${value}`;
}

function removeUrlParameter (url, key) {
  if (!url) {
    url = window.location.href;
  }
  const hashParts = url.split('#');
  const regex = new RegExp(`([?&])${key}=.*?(&|#|$)`, 'i');

  if (hashParts[0].match(regex)) {
    // REMOVE KEY AND VALUE
    url = hashParts[0].replace(regex, '$1');

    // REMOVE TRAILING ? OR &
    url = url.replace(/([?&])$/, '');

    // ADD HASH
    if (typeof hashParts[1] !== 'undefined' && hashParts[1] !== null) {
      url += `#${hashParts[1]}`;
    }
  }

  return url;
}

function replaceUrlState (url) {
  if (typeof window.history.replaceState === 'function') {
    history.replaceState({}, '', url);
  }
}

// Bolden selected part of a string
function selectSubstring (str, search) {
  const startIndex = str.toLowerCase().indexOf(search.toLowerCase());
  const endIndex = startIndex + search.length;
  if (startIndex !== -1) {
    return `<span>${str.slice(0, startIndex)}<span class="selected">${str.slice(
        startIndex,
        endIndex
      )}</span>${str.slice(endIndex)}</span>`;
  }
  return str;
}

// convert string to capital
const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// convert Objects (or array of objects) to captial
function toCapital (o) {
  let newO;
  let origKey;
  let newKey;
  let value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCapital(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (Object.prototype.hasOwnProperty.call(o, origKey)) {
        newKey = capitalize(origKey);
        value = o[origKey];
        if (
          value instanceof Array ||
            (value !== null && value.constructor === Object)
        ) {
          value = toCapital(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export default {
  getURLParameter,
  updateUrlParameter,
  removeUrlParameter,
  replaceUrlState,
  selectSubstring,
  toCapital,
  capitalize,
  updateUrlHash
};
