/* eslint-disable */
let url;
let origin;

if (!document.currentScript) {
  const scripts = document.querySelectorAll("script");
  let currentScript;

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf("js/app")) {
      currentScript = scripts[i];
    }
  }

    var parser = document.createElement('a');
    parser.href = currentScript.src;
    origin = parser.protocol + '//' + parser.host;
} else {
  url = new URL(document.currentScript.src);
  origin = url.origin;
}
__webpack_public_path__ =  origin + __webpack_public_path__;
