export const facebookCartAdd = function (ProductObject) {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'AddToCart', {
      content_name: ProductObject.name,
      content_ids: [`${ProductObject.productId} ${ProductObject.variant} ${ProductObject.size}`],
      content_type: 'product',
      value: ProductObject.price,
      currency: ProductObject.currency
    });
  }
};

export const facebookProductViewed = function (ProductObject) {
  if (typeof fbq !== 'undefined') {
    fbq('track',
      'ViewContent',
      {
        content_category: ProductObject.categoryCollection,
        content_ids: [ProductObject.productId],
        content_type: 'product_group',
        currency: ProductObject.currency,
        value: ProductObject.price
      });
  }
};

export const facebookLoyaltyRegistrationStarted = function () {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'StartRegistration');
  }
};

export const facebookLoyaltyRegistrationCompleted = function () {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'CompleteRegistration');
  }
};
