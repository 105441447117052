// import cloudinary from 'cloudinary-core';
import cloudinary from 'cloudinary-tiny-js';
import { BGCOLOR, SRCSET_STEPS } from '@/Scripts/constants';

// const cl = new cloudinary.Cloudinary({ cloud_name: 'norrona', secure: true });

const cl = cloudinary({
  /** The name of your Cloudinary account, a unique public identifier for URL building and API access. */
  cloudName: 'norrona',
  assetType: 'image',
  /** Use https instead of http */
  secure: true,
  /** Override the subdomain when using a private CDN distribution. */
  cdnSubdomain: 'res',
  /** A fully customisable CNAME. */
  cname: 'res.cloudinary.com'
});

function transformation (width = 0, height = 0, bgcolor = BGCOLOR.DEFAULT, crop = 'pad', quality = 'auto', missingImg = 'imgmissing.jpg', focal = {}, border) {
  const tr = [];
  const mainChain = {
    crop: crop,
    format: 'auto',
    defaultImage: missingImg,
    flags: 'progressive.lossy',
    quality: 'auto'
  };

  if (Object.keys(focal).length) {
    const focalChain = { x: focal.x, y: focal.y, width: focal.w, height: focal.h, crop: 'crop', gravity: 'xy_center' };
    tr.push(focalChain);
    if (bgcolor !== 'transparent') {
      const bgChain = { background: `rgb:${bgcolor.replace('#', '')}` };
      tr.push(bgChain);
    }
  } else {
    if (border) {
      mainChain.border = bgcolor !== 'transparent' ? `${Math.round(border)}px_solid_rgb:${bgcolor.replace('#', '')}` : `${Math.round(border)}px_solid_transparent`;
      width = width - (border * 2);
      height = height - (border * 2);
    }
    if (bgcolor !== 'transparent') {
      mainChain.background = `rgb:${bgcolor.replace('#', '')}`;
    }
  }

  if (width > 0) {
    mainChain.width = Math.round(width);
  }
  if (height > 0) {
    mainChain.height = Math.round(height);
  }
  tr.push(mainChain);
  return tr;
}

export default {
  transformation,
  generateVideo (fileId, filetype = 'mp4') {
    cl.assetType = 'video';
    return cl(fileId);
  },
  generateFocal (fileId, data = {}) {
    const width = data.width || 0;
    const height = data.height || 0;
    const bgcolor = data.bgcolor || BGCOLOR.DEFAULT;
    const border = data.border || null;
    const crop = data.crop || 'pad';
    const quality = data.quality || 'auto';
    let filetype = data.filetype || 'jpg';
    const missingImg = data.missingImg || 'imgmissing.jpg';
    const focal = data.focal || {};
    if (bgcolor === 'transparent') {
      filetype = 'png';
    }

    const tr = transformation(width, height, bgcolor, crop, quality, missingImg, focal, border);
    cl.assetType = 'image';
    return cl(`${encodeURIComponent(fileId)}.${filetype}`, tr);
  },
  generateSrcset (fileId, focal, bgcolor = null, proportion = null, borderPercent = null) {
    let str = '';
    for (let i = 0; i < SRCSET_STEPS.length; i++) {
      const step = SRCSET_STEPS[i];
      const width = Math.round(step);
      const border = width * (borderPercent / 100);
      const height = proportion ? width / proportion : 0;
      const url = this.generateFocal(fileId, { width: width, height: height, bgcolor: bgcolor, focal: focal, border: border });
      str += `${url} ${SRCSET_STEPS[i]}w`;
      if (i < SRCSET_STEPS.length - 1) {
        str += ',';
      }
    }
    return str;
  },
  // deprecated when imagecollector is totaly implemented
  generate (fileId, width = 0, height = 0, bgcolor = BGCOLOR.DEFAULT, crop = 'pad', quality = 'auto', filetype = 'jpg', missingImg = 'imgmissing.jpg', focal = {}) {
    if (bgcolor === 'transparent') {
      filetype = 'png';
    }
    const tr = transformation(width, height, bgcolor, crop, quality, missingImg, focal);
    cl.assetType = 'image';
    return cl(`${fileId}.${filetype}`, tr);
  }

};
