import Api from '@/Scripts/api/api';
import UrlHelper from '@/Scripts/lib/helper/urlHelper.js';

const state = {
  OrderSummary: {
    SubTotal: '',
    OrderDiscounts: [],
    LoyaltyDiscount: null,
    LoyaltyPromotionCode: null,
    LoyaltyPromotionStatus: 0,
    LoyaltyPointsForThisPurchase: 0,
    ShippingDiscountTotal: '',
    ShippingTotalOrginal: null,
    ShippingTotal: '',
    ShippingSubtotal: '',
    TaxTotal: '',
    ShippingTaxTotal: '',
    CartTotal: '',
    Total: {
      Amount: 0,
      CurrencyCode: ''
    },
    MaxDiscount: '',
    TaxIncluded: true,
    CashPoints: {
      CustomerId: '',
      Email: '',
      Currency: '',
      Points: 0,
      OriginalCurrency: '',
      OriginalPoints: 0
    },
    HandlingCost: '',
    HandlingCostMoney: {
      Amount: 0,
      CurrencyCode: ''
    }
  },
  CartItems: [],
  magazinesData: [],
  localViewState: {
    loadingVoucher: false,
    loadingCashpoints: false,
    loadingCartRemoval: false,
    loadingOrderSummary: false,
    loadingKlarna: false,
    loadingCartItems: false,
    loadingQuantityChange: false,
    invalidPersonalCode: false,
    requestFailed: false,
    isLoyalty: false,
    isSignedIn: false,
    initialLoadCompleted: false,
    klarnaV3: true
  },
  quantityChangeData: {
    inProgress: false,
    hasBeenStopped: {},
    lastRequestObject: {},
    queue: {}
  },
  SelectedPickupLocation: null
};

const getters = {
  cartCount () {
    let count = 0;

    state.CartItems.forEach(element => {
      count = count + element.Quantity;
    });

    return count;
  },
  loyaltyPromotionCode: function () {
    if (state.OrderSummary.LoyaltyPromotionCode) {
      return state.OrderSummary.LoyaltyPromotionCode;
    } else {
      return null;
    }
  },
  loyaltyPromotionStatus: function () {
    if (state.OrderSummary.LoyaltyPromotionStatus) {
      return state.OrderSummary.LoyaltyPromotionStatus;
    } else {
      return null;
    }
  },
  loyaltyPointsForThisPurchase: function () {
    if (state.OrderSummary.LoyaltyPointsForThisPurchase) {
      return state.OrderSummary.LoyaltyPointsForThisPurchase;
    } else {
      return null;
    }
  },
  discountCode: function () {
    if (state.OrderSummary.LoyaltyPromotionCode) {
      return state.OrderSummary.LoyaltyPromotionCode;
    }
    if (
      state.OrderSummary.OrderDiscounts &&
      state.OrderSummary.OrderDiscounts.length > 0 &&
      state.OrderSummary.OrderDiscounts[0].DisplayName
    ) {
      return state.OrderSummary.OrderDiscounts[0].DisplayName;
    } else {
      return '';
    }
  },
  discountValue: function () {
    if (
      state.OrderSummary.OrderDiscounts &&
      state.OrderSummary.OrderDiscounts.length > 0 &&
      state.OrderSummary.OrderDiscounts[0].DiscountFormated
    ) {
      return state.OrderSummary.OrderDiscounts[0].DiscountFormated;
    } else {
      return '';
    }
  },
  discountSavedAmount: function () {
    if (
      state.OrderSummary.OrderDiscounts &&
      state.OrderSummary.OrderDiscounts.length > 0 &&
      state.OrderSummary.OrderDiscounts[0].PromotionInformation
    ) {
      return state.OrderSummary.OrderDiscounts[0].PromotionInformation.SavedAmount;
    } else {
      return 0;
    }
  },
  discountType: function () {
    if (
      state.OrderSummary.OrderDiscounts &&
      state.OrderSummary.OrderDiscounts.length > 0 &&
      state.OrderSummary.OrderDiscounts[0].Type >= 0
    ) {
      return state.OrderSummary.OrderDiscounts[0].Type;
    } else {
      return 0;
    }
  },
  discountName: function () {
    if (
      state.OrderSummary.OrderDiscounts &&
      state.OrderSummary.OrderDiscounts.length > 0
    ) {
      return state.OrderSummary.OrderDiscounts[0].DisplayName;
    } else {
      return '';
    }
  },
  discountPercentage: function () {
    if (
      state.OrderSummary.OrderDiscounts &&
      state.OrderSummary.OrderDiscounts.length > 0
    ) {
      return state.OrderSummary.OrderDiscounts[0].Percentage;
    } else {
      return 0;
    }
  },
  loadingOrderSummary () {
    return state.localViewState.loadingQuantityChange || state.localViewState.loadingCartRemoval || state.localViewState.loadingOrderSummary;
  },
  suspendKlarna () {
    return state.localViewState.loadingKlarna;
    // return state.localViewState.loadingVoucher || state.localViewState.loadingCashpoints || state.localViewState.loadingCartItems || state.localViewState.loadingQuantityChange || state.localViewState.loadingCartRemoval || state.localViewState.loadingOrderSummary;
  },
  handlingCost () {
    if (
      state.OrderSummary.HandlingCost &&
      state.OrderSummary.HandlingCostMoney &&
      state.OrderSummary.HandlingCostMoney.Amount > 0
    ) {
      return state.OrderSummary.HandlingCost;
    }
    return '';
  }
};

const actions = {
  updateOrderSummary ({ commit, state }, OrderSummaryResponseData) {
    Object.keys(state.OrderSummary).forEach(key => {
      if (OrderSummaryResponseData[key] !== undefined) {
        commit('set' + key, OrderSummaryResponseData[key]);
      }
    });
  },
  updateKlarna ({ commit, rootState, state }) {
    if (!state.localViewState.klarnaV3) {
      commit('setLoadingKlarna', true);
      Api.get(UrlHelper.getCheckoutApiUrl('payment/klarna/update', rootState.appContext.cartName)).then(response => {
        commit('setLoadingKlarna', false);
      });
    } else {
      commit('setLoadingKlarna', false);
    }
  },
  setUserStatusFromBody ({ commit }) {
    commit('setIsSignedIn', !!document.body.getAttribute('data-identify'));
    commit('setIsLoyalty', document.body.getAttribute('data-is-loyalty') === 'True');
  },
  getCashpoints ({ commit, rootState, state }) {
    if (state.localViewState.isLoyalty) {
      Api.get(UrlHelper.getCheckoutApiUrl('cashpoints', rootState.appContext.cartName))
        .then(response => {
          commit('setCashPoints', response.data);
        })
        .catch(err => {
          throw err;
        });
    }
  },
  getCompleteCheckout ({ commit, dispatch, rootState }) {
    commit('setLoadingOrderSummary', true);
    commit('setLoadingCartItems', true);
    Api.get(UrlHelper.getCheckoutApiUrl('', rootState.appContext.cartName))
      .then(response => {
        if (!response.data && response.status === 200) {
          commit('setCartItems', []);
        } else {
          dispatch('updateOrderSummary', response.data.OrderSummary);
          commit('setCartItems', response.data.CartItems);
          commit('setKlarnaV3', response.data.Klarna_v3);
        }
        commit('setInitialLoadCompleted', true);
        commit('setLoadingOrderSummary', false);
        commit('setLoadingCartItems', false);
        commit('setMagazines', response.data.Magazines);
      })
      .catch(err => {
        throw err;
      });
  },
  getOrderSummary ({ commit, dispatch, rootState }) {
    commit('setLoadingOrderSummary', true);

    Api.get(UrlHelper.getCheckoutApiUrl('orderSummary', rootState.appContext.cartName))
      .then(response => {
        dispatch('updateOrderSummary', response.data.OrderSummary);
        commit('setLoadingOrderSummary', false);
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  },
  setShippingOption ({ commit, dispatch, rootState }, data) {
    // CHECK IF SHIPPING COST HAS CHANGED BEFORE DOING THIS UPDATE
    if ((state.OrderSummary.ShippingTotalOrginal.Amount * 100) === data.price) {
      // Do not update. There is no change in shipping cost
      return;
    }

    commit('setLoadingOrderSummary', true);

    Api.post(UrlHelper.getCheckoutApiUrl('setshippingoption', rootState.appContext.cartName), null, { Id: data.id, Name: data.name }).then(response => {
      dispatch('updateOrderSummary', response.data.OrderSummary);
      commit('setLoadingOrderSummary', false);
    })
      .catch(err => {
        throw err;
      });
  },
  quantityChange ({ commit, dispatch, state, rootState }, obj) {
    // If allready an API call in progress, it stops the change and adds it to the queue. (Replaces if same SKU exists)
    if (state.quantityChangeData.inProgress) {
      commit('setChangeQueueObject', obj);

    // If not, performs the change.
    } else {
      commit('setLoadingKlarna', true);
      commit('setQuantityChangeInProgress', true);
      commit('setQuantityChangeLoading', true);

      // ShipmentId: -1: We are getting correct ShipmentId backend
      Api.post(UrlHelper.getCheckoutApiUrl('cartitem/change', rootState.appContext.cartName), '', { ShipmentId: -1, Code: obj.sku, Quantity: obj.quantity })
        .then(response => {
          commit('setQuantityChangeInProgress', false);
          // If any changes has been stopped during the last request, and calls itself with the latest change.
          const queueArray = Object.keys(state.quantityChangeData.queue);

          if (queueArray.length) {
            dispatch('quantityChange', state.quantityChangeData.queue[queueArray[0]]);
            commit('deleteChangeQueueObject', state.quantityChangeData.queue[queueArray[0]]);

            // If not, commits the changes to the store, and updates prices for the customer.
          } else {
            if (!response.data && response.status === 200) {
              commit('setCartItems', []);
            } else {
              dispatch('updateOrderSummary', response.data.OrderSummary);
              dispatch('updateKlarna');
              commit('setCartItems', response.data.CartItems);
            }
            dispatch('bag/getCartItems', {}, { root: true });
            commit('setQuantityChangeLoading', false);
          }
        })
        .catch(err => {
          throw err;
        });
    }
  },
  addCoupon ({ commit, dispatch, rootState }, value) {
    commit('setLoadingVoucher', true);
    commit('setInvalidPersonalCode', false);
    commit('setRequestFailed', false);
    commit('setLoyaltyPromotionCode', null);
    commit('setLoyaltyPromotionStatus', null);
    commit('setLoyaltyPointsForThisPurchase', null);
    commit('setLoadingKlarna', true);

    Api.post(UrlHelper.getCheckoutApiUrl('coupon', rootState.appContext.cartName), '', { input: value })
      .then(response => {
        dispatch('updateKlarna');
        dispatch('updateOrderSummary', response.data.OrderSummary);
        commit('setLoadingVoucher', false);
      })
      .catch(err => {
        commit('setLoadingKlarna', false);
        commit('setLoadingVoucher', false);
        if (err.response.status === 400) {
          commit('setInvalidPersonalCode', true);
          return;
        } else {
          commit('setRequestFailed', true);
        }

        if (err.response.status !== 404) {
          throw err;
        }
      });
  },
  removeCoupon ({ commit, dispatch, rootState }) {
    commit('setLoadingVoucher', true);
    commit('setLoadingKlarna', true);
    const code = state.OrderSummary.OrderDiscounts[0] ? state.OrderSummary.OrderDiscounts[0].DisplayName : state.OrderSummary.LoyaltyPromotionCode;
    Api.post(UrlHelper.getCheckoutApiUrl('coupon/remove', rootState.appContext.cartName), '', {
      input: code
    })
      .then(response => {
        if (!response.data && response.status === 200) {
          commit('setCartItems', []);
        } else {
          dispatch('updateKlarna');
          dispatch('updateOrderSummary', response.data.OrderSummary);
          commit('setCartItems', response.data.CartItems);
        }
        commit('setLoadingVoucher', false);
        commit('setInvalidPersonalCode', false);
        commit('setRequestFailed', false);
      })
      .catch(err => {
        commit('setLoadingVoucher', false);
        throw err;
      });
  },
  useCashpoints ({ commit, dispatch, rootState }) {
    commit('setLoadingCashpoints', true);
    commit('setLoadingKlarna', true);
    Api.post(UrlHelper.getCheckoutApiUrl('cashpoints', rootState.appContext.cartName), '', '')
      .then(response => {
        dispatch('updateKlarna');
        dispatch('updateOrderSummary', response.data.OrderSummary);
        commit('setLoadingCashpoints', false);
      })
      .catch(err => {
        throw err;
      });
  },
  removeCashpoints ({ commit, dispatch, rootState }) {
    commit('setLoadingCashpoints', true);

    commit('setLoadingKlarna', true);
    Api.delete(UrlHelper.getCheckoutApiUrl('cashpoints', rootState.appContext.cartName), '', '')
      .then(response => {
        dispatch('updateKlarna');
        dispatch('updateOrderSummary', response.data.OrderSummary);
        commit('setLoadingCashpoints', false);
      })
      .catch(err => {
        throw err;
      });
  },
  removeCartItem ({ commit, dispatch, rootState }, sku) {
    commit('setLoadingCartRemoval', true);
    commit('setLoadingKlarna', true);
    Api.post(UrlHelper.getCartApiUrl('remove', rootState.appContext.cartName), '', { code: sku })
      .then(() => {
        dispatch('updateKlarna');
        dispatch('getCompleteCheckout'); // This should have a response we can pass back in.
        dispatch('bag/getCartItems', {}, { root: true });
        commit('setLoadingCartRemoval', false);
      })
      .catch(err => {
        throw err;
      });
  }
};

const mutations = {
  setSubTotal (state, value) {
    state.OrderSummary.SubTotal = value;
  },
  setOrderDiscounts (state, value) {
    state.OrderSummary.OrderDiscounts = value;
  },
  setLoyaltyDiscount (state, value) {
    state.OrderSummary.LoyaltyDiscount = value;
  },
  setLoyaltyPromotionCode (state, value) {
    state.OrderSummary.LoyaltyPromotionCode = value;
  },
  setLoyaltyPromotionStatus (state, value) {
    state.OrderSummary.LoyaltyPromotionStatus = value;
  },
  setLoyaltyPointsForThisPurchase (state, value) {
    state.OrderSummary.LoyaltyPointsForThisPurchase = value;
  },
  setShippingDiscountTotal (state, value) {
    state.OrderSummary.ShippingDiscountTotal = value;
  },
  setShippingTotalOrginal (state, value) {
    state.OrderSummary.ShippingTotalOrginal = value;
  },
  setShippingTotal (state, value) {
    state.OrderSummary.ShippingTotal = value;
  },
  setShippingSubtotal (state, value) {
    state.OrderSummary.ShippingSubtotal = value;
  },
  setTaxTotal (state, value) {
    state.OrderSummary.TaxTotal = value;
  },
  setShippingTaxTotal (state, value) {
    state.OrderSummary.ShippingTaxTotal = value;
  },
  setCartTotal (state, value) {
    state.OrderSummary.CartTotal = value;
  },
  setTotal (state, value) {
    state.OrderSummary.Total = value;
  },
  setMaxDiscount (state, value) {
    state.OrderSummary.MaxDiscount = value;
  },
  setTaxIncluded (state, value) {
    state.OrderSummary.TaxIncluded = value;
  },
  setCashPoints (state, value) {
    state.OrderSummary.CashPoints = value;
  },
  setCartItems (state, value) {
    state.CartItems = value;
  },
  setMagazines (state, value) {
    state.magazinesData = value;
  },
  setLoadingVoucher (state, value) {
    state.localViewState.loadingVoucher = value;
  },
  setLoadingCashpoints (state, value) {
    state.localViewState.loadingCashpoints = value;
  },
  setLoadingCartRemoval (state, value) {
    state.localViewState.loadingCartRemoval = value;
  },
  setInvalidPersonalCode (state, value) {
    state.localViewState.invalidPersonalCode = value;
  },
  setRequestFailed (state, value) {
    state.localViewState.requestFailed = value;
  },
  setIsLoyalty (state, value) {
    state.localViewState.isLoyalty = value;
  },
  setIsSignedIn (state, value) {
    state.localViewState.isSignedIn = value;
  },
  setKlarnaV3 (state, value) {
    state.localViewState.klarnaV3 = value;
  },
  setQuantityChangeInProgress (state, value) {
    state.quantityChangeData.inProgress = value;
  },
  setQuantityChangeLoading (state, value) {
    state.localViewState.loadingQuantityChange = value;
  },
  setInitialLoadCompleted (state, value) {
    state.localViewState.initialLoadCompleted = value;
  },
  setLoadingOrderSummary (state, value) {
    state.localViewState.loadingOrderSummary = value;
  },
  setLoadingKlarna (state, value) {
    state.localViewState.loadingKlarna = value;
  },
  setLoadingCartItems (state, value) {
    state.localViewState.loadingCartItems = value;
  },
  setChangeQueueObject (state, value) {
    state.quantityChangeData.queue[value.sku] = value;
  },
  deleteChangeQueueObject (state, value) {
    delete state.quantityChangeData.queue[value.sku];
  },
  setHandlingCost (state, value) {
    state.OrderSummary.HandlingCost = value;
  },
  setHandlingCostMoney (state, value) {
    state.OrderSummary.HandlingCostMoney = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
