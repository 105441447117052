import { TRACKINGSTRINGS } from '@/Scripts/constants';
import { SegmentsTracker, SegmentsIdentify } from '@/Scripts/lib/tracking/segmentsTrackerV2';
import { gaWishlistAdd, trackImpressions, gaCartAdd, gaCartRemove, gaProductViewed, gaLoyaltyRegistrationCompleted, gaLoyaltyRegistrationStarted } from '@/Scripts/lib/tracking/googleAnalyticsTrackerV2';
import { facebookCartAdd, facebookProductViewed, facebookLoyaltyRegistrationCompleted, facebookLoyaltyRegistrationStarted } from '@/Scripts/lib/tracking/facebookTrackerV2';
import GenerateImageHelper from '@/Scripts/lib/helper/generateImageHelper.js';

// ******* Converter functions ********* //
function formatPriceFloat (str) {
  if (!str) {
    return '';
  }
  return parseFloat(parseFloat(str.toString().replace(',', '.')).toFixed(2));
}
function getUrl (val) {
  return `${location.protocol}//${location.host}${val}`;
}
function getOldPriceObj (oldProductModel) {
  if (typeof oldProductModel.Price !== 'undefined' && oldProductModel.Price) {
    return oldProductModel.Price.UnitPrice;
  } else {
    console.warn('Missing price in JSON');
  }
  return {};
}

function convertCartItems (cartItems, cart) {
  const products = [];
  for (let i = 0; i < cartItems.length; i++) {
    products.push(TrackerCartProductObject({ product: cartItems[i], currency: cart.Cart.Currency }));
  }
  return products;
}

const getTotalOutletDiscount = function (cartItems) {
  let total = 0;
  cartItems.forEach((item) => {
    total += item.PlacedPrice.Amount - item.DiscountedPrice.Amount;
  });
  return total;
};

const removeGender = function (str) {
  if (!str) {
    return '';
  }
  return str.replace("W's", '').replace("M's", '').replace('(M)', '').replace('(W)', '').replace('(M/W)', '').trim();
};
const formatGender = function (gender) {
  return {
    Female: "Women's",
    Male: "Men's",
    Unisex: 'Unisex'
  }[gender] || gender;
};

// ******* PRODUCT MAPPING FUNCTIONS ********* //

export const TrackerProductObject = function (
  {
    index = 0,
    product = {},
    colorVariant = {},
    size = '',
    outlet = false,
    pageType = '',
    addToCartType = '',
    search = false,
    recoVersion = '',
    sourceObject = null,
    price = null,
    fullPrice = null,
    currency = ''

  }) {
  const productId = product.Code || product.ProductCode;
  const colorCode = colorVariant.ColorCode || (colorVariant.Color ? colorVariant.Color.Code : '');
  const sku = `${productId} ${colorCode}`;
  price = price || (colorVariant.Price ? colorVariant.Price.VariantAmount : '');
  fullPrice = fullPrice || (colorVariant.Price ? colorVariant.Price.FullPrice : '');
  currency = currency || (colorVariant.Price ? colorVariant.Price.CurrencyCode : '');
  const productObject = {
    productId,
    sku,
    categoryCollection: product.Concept,
    categoryActivity: product.DesignedFor,
    category: product.Category,
    search: search,
    recommendation: recoVersion !== '',
    recommendationPosition: index,
    recommendationVersion: recoVersion || 0,
    brand: 'Norrøna',
    name: product.Name,
    productName: removeGender(product.Name),
    gender: formatGender(product.Gender),
    variant: colorVariant.ColorCode,
    size: size,
    price: formatPriceFloat(price),
    fullPrice: formatPriceFloat(fullPrice),
    currency: currency,
    url: colorVariant.Url,
    imageUrl: {
      Url: GenerateImageHelper.generateFocal(colorVariant.ModelShotPath)
    },
    isOutlet: outlet,
    quantity: product.Quantity ? product.Quantity : 1,
    hasModelSize: false
  };

  if (pageType) { productObject.pageType = pageType; }
  if (addToCartType) { productObject.addToCartType = addToCartType; }
  if (sourceObject) { productObject.sourceObject = sourceObject; }

  return productObject;
};

// Use for cart related tracking
export const TrackerCartProductObject = function (
  {
    product = {},
    currency = ''
  }) {
  const imagePath = product.Variant.PackshotMain || product.Variant.ShotPack || product.Variant.ModelshotMain;
  const productObject = {
    productId: product.ProductCode,
    sku: `${product.ProductCode} ${product.Variant.Color.Code}`,
    categoryCollection: product.Concept,
    categoryActicity: product.DesignedFor,
    brand: 'Norrøna',
    name: product.Name,
    variant: product.Variant.Color.Code,
    price: product.DiscountedPrice.Amount,
    fullPrice: product.PlacedPrice.Amount,
    size: product.Variant.Size,
    currency: currency || product.PlacedPrice.CurrencyCode,
    url: getUrl(product.Url),
    imageUrl: GenerateImageHelper.generateFocal(imagePath, { width: 400, height: 400, bgcolor: '#FCFCFC' }),
    isOutlet: product.Variant.Outlet,
    quantity: product.Quantity
  };
  return productObject;
};

// parse old product model // exist on wishlist and Selected Look osv....
export const OldTrackerProductObject = function ({ oldProductModel = {}, selectedVariant = {}, priceObj = null, reco = {}, brand = 'Norrøna', addType = null, pageType = null }) {
  if (!priceObj) {
    priceObj = getOldPriceObj(oldProductModel);
  }
  const traits = {
    productId: oldProductModel.Code,
    sku: `${oldProductModel.Code} ${selectedVariant.ColorCode}`,
    categoryCollection: oldProductModel.Concept,
    categoryActivity: oldProductModel.DesignedFor,
    category: oldProductModel.Category,
    search: oldProductModel.search ? oldProductModel.search : false,
    recommendation: reco ? reco.on : false,
    recommendationPosition: reco ? reco.pos : 0,
    recommendationVersion: reco && reco.on ? reco.version : 0,
    brand: brand,
    name: oldProductModel.Name,
    variant: selectedVariant.ColorCode,
    size: selectedVariant.size,
    price: formatPriceFloat(priceObj.Amount),
    currency: priceObj.CurrencyCode,
    url: getUrl(selectedVariant.Url),
    imageUrl: selectedVariant.ImageUrl,
    isOutlet: selectedVariant.Outlet,
    quantity: oldProductModel.Quantity ? oldProductModel.Quantity : 1,
    hasModelSize: false
  };
  if (addType) {
    traits.addToCartType = addType;
  }

  if (pageType) {
    traits.pageType = pageType;
  }
  return traits;
};

// ******* IDENTIFY ********* //

export const TrackerIdentify = function (email, data) {
  SegmentsIdentify(email, data);
};

// ******* TRACKING ********* //
// Product Impressions (product list view)
export const TrackerImpressions = function (ImpressionObject, name) {
  trackImpressions(ImpressionObject, name);
};

// Add to cart
export const TrackerCartAdd = function (ProductObject, listName) {
  SegmentsTracker(TRACKINGSTRINGS.PRODUCT_ADDED_CART, ProductObject);
  gaCartAdd(ProductObject, listName);
  facebookCartAdd(ProductObject);
};
// Remove from cart
export const TrackerCartRemove = function (ProductObject, quantity) {
  SegmentsTracker(TRACKINGSTRINGS.PRODUCT_REMOVED_CART, ProductObject);
  gaCartRemove(ProductObject, quantity);
};

export const TrackerCheckoutStarted = function (cart, cartItems) {
  SegmentsTracker(TRACKINGSTRINGS.CHECKOUT_STARTED, {
    cartId: cart.Cart.OrderNumber,
    restoreUrl: cart.RestoreUrl,
    affiliation: 'web',
    value: cart.Cart.SubTotal.Amount + getTotalOutletDiscount(cartItems),
    revenue: cart.Total.Amount,
    shipping: cart.ShippingCost.Amount,
    tax: cart.Cart.Tax.Amount,
    discount: cart.Cart.OrderDiscountTotal.Amount + getTotalOutletDiscount(cartItems),
    coupon: '',
    currency: cart.Cart.Currency,
    products: convertCartItems(cartItems, cart)
  });
};

export const TrackerCartViewed = function (cart, cartItems) {
  if (cartItems.length > 0) {
    SegmentsTracker(TRACKINGSTRINGS.CART_VIEWED, {
      cartId: cart.Cart.OrderNumber,
      products: convertCartItems(cartItems, cart)
    });
  }
};

// Product page viewed
export const TrackerProductViewed = function (ProductObject) {
  SegmentsTracker(TRACKINGSTRINGS.PRODUCT_VIEWED, ProductObject);
  gaProductViewed(ProductObject);
  facebookProductViewed(ProductObject);
};

// Product card clicked
export const TrackerProductClicked = function (ProductObject) {
  SegmentsTracker(TRACKINGSTRINGS.PRODUCT_CLICKED, ProductObject);
};

// Add to wishlist
export const TrackerWishlistAdd = function (ProductObject) {
  SegmentsTracker(TRACKINGSTRINGS.PRODUCT_ADDED_WISHLIST, ProductObject);
  gaWishlistAdd(ProductObject);
};

// BackinstockNotify
export const TrackerBackinstockNotify = function (email, notifyType, ProductObject) {
  const obj = { ...ProductObject, email, notifyType };
  SegmentsTracker(TRACKINGSTRINGS.NOTIFY_ME_CLICKED, obj);
};

// Frontpage HeroBlock CTA click
export const TrackerHeroBlockClicked = function (blockId, name, position, buttonClicked, buttonType, url, surface) {
  SegmentsTracker(TRACKINGSTRINGS.BLOCK_CLICKED, {
    blockId,
    name,
    position,
    buttonClicked,
    buttonType,
    url,
    surface
  });
};

// Norrøna Events tracking
export const TrackerEventsClicked = function (trackerString, {
  id = '',
  name = '',
  type = '',
  location = '',
  activity = '',
  startTime = '',
  version = '01',
  surface = ''
}) {
  SegmentsTracker(trackerString, {
    id,
    name,
    type,
    location,
    activity,
    startTime,
    version,
    surface
  });
};

// Internal Ads tracking
export const TrackerAdClicked = function ({
  internalAdId = '',
  landingPage = '',
  position = null,
  gender = '',
  version = '01',
  surface = ''
}) {
  SegmentsTracker(TRACKINGSTRINGS.AD_CLICKED, {
    brand: 'Norrøna',
    internalAdId,
    landingPage,
    position,
    gender,
    version,
    surface
  });
};

// Tracking for search
export const TrackerSearch = function (query) {
  SegmentsTracker(TRACKINGSTRINGS.PRODUCT_SEARCHED, {
    query
  });
};

export const TrackerSearchStarted = function (query) {
  SegmentsTracker(TRACKINGSTRINGS.SEARCH_STARTED);
};

export const TrackerSearchResultClicked = function (destinationUrl, isFromSearchPreview) {
  SegmentsTracker(TRACKINGSTRINGS.SEARCH_RESULT_CLICKED, {
    destination_url: destinationUrl,
    origin: isFromSearchPreview ? 'search_preview' : 'search_result_page'
  });
};

export const TrackerSearchResultViewed = function (hits, query) {
  SegmentsTracker(TRACKINGSTRINGS.SEARCH_RESULT_VIEWED, {
    hits: hits,
    query: query
  });
};

export const TrackerProductGuideViewed = function (gender, guideName) {
  SegmentsTracker(TRACKINGSTRINGS.PRODUCT_GUIDE_VIEWED, { brand: 'Norrøna', gender, guideName });
};

export const TrackerLoyaltyRegistrationCompleted = function () {
  gaLoyaltyRegistrationCompleted();
  facebookLoyaltyRegistrationCompleted();
};

export const TrackerLoyaltyRegistrationStarted = function (email) {
  gaLoyaltyRegistrationStarted();
  facebookLoyaltyRegistrationStarted();
  SegmentsTracker(TRACKINGSTRINGS.CLICKED_JOIN_LOYALTY, { email: email });
};

export const TrackerMenuItemClicked = function (levelOne, levelTwo, levelThree) {
  const menuversion = 'version-230621';
  SegmentsTracker(TRACKINGSTRINGS.MENU_ITEM_CLICKED, { version: menuversion, level_1: levelOne, level_2: levelTwo, level_3: levelThree });
};

export const TrackerStoreAvailabilityClicked = function ({ code = '', sku = '', outOfStock = false, storeName = '' }) {
  SegmentsTracker(TRACKINGSTRINGS.STORE_AVAILABILITY_CLICKED, {
    product_id: code,
    sku: sku,
    out_of_stock: outOfStock,
    affiliation: storeName
  });
};

export const TrackerSizeGuideClicked = function (ProductObject) {
  SegmentsTracker(TRACKINGSTRINGS.SIZEGUIDE_CLICKED, ProductObject);
};

export const TrackerFilterSet = function ({ filterName = '', filterValue = '', outlet = false, gender = '', surface = '' }) {
  SegmentsTracker(TRACKINGSTRINGS.FILTER_HEADER_CLICKED, {
    filterName,
    filterValue,
    isOutlet: outlet,
    gender,
    surface

  });
};
