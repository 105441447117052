/**
 * @file For running a/b-tests
 *
 *
 * @author Torbjørn Høvde
 * @version 1.0
 *
 * $_abtest_check(testid) - returs true if test is running
 *  running: turn on of test
 *  autorun: adds the class to to body. For testing!
 *  forceuse: run the test without adding class to body.
 *
 * Correct format: { id: 'bag-size',
 *                   class: 'ab-big-bag',
 *                   running: true,
 *                   forceuse: false,
 *                   autorun: false }
 *
 */

export default {

  data () {
    return {
      abtest_tests: [
        // Enables collapsed scroll-list, and disables fixed expand on all groups
        // show all colors button takes expand-buttons place
        { id: 'productlist-group-scroll',
          class: 'ab-group-scroll',
          running: true,
          forceuse: false,
          autorun: false },
        // Enables load more on expanded groups
        // show all colors button turns into link
        { id: 'productlist-group-loadmore',
          class: 'ab-group-loadmore',
          running: true,
          forceuse: false,
          autorun: false },
        { id: 'header-usp-bar',
          class: 'view-uspbar',
          running: false,
          forceuse: false,
          autorun: false },
        { id: 'click-collect-two-buttons',
          class: 'cc2btns',
          running: true,
          forceuse: false,
          autorun: false }
      ]
    };
  },
  methods: {
    $_abtest_check (id) {
      const test = this.abtest_tests.find(obj => obj.id === id);
      if (test) {
        if (test.forceuse) return true;
        if (document.querySelector('body').classList.contains(test.class)) {
          return test.running;
        }
      }
      return false;
    }
  },
  created () {
    this.abtest_tests.forEach((item) => {
      if (item.autorun && !document.querySelector('body').classList.contains(item.class)) {
        document.querySelector('body').classList.add(item.class);
      }
    });
  }
};
