import Api from '@/Scripts/api/api';

export default {
  getBagItems: (type) => {
    return Api.get(`/api/cart/${type}/`);
  },
  addBagItem: (type, sku, listName) => {
    return Api.post(`/api/cart/${type}/add`, '', { code: sku, listName });
  },
  removeBagItem: (type, sku) => {
    return Api.post(`/api/cart/${type}/remove`, '', { code: sku });
  },
  addCartMagazine: (type, sku) => {
    return Api.post(`/api/cart/${type}/add/magazine`, '', { code: sku });
  },
  removeCartMagazine: (type, sku) => {
    return Api.post(`/api/cart/${type}/remove/magazine`, '', { code: sku });
  },
  addSkiPackage: (skiSKU, bindingSKU, mounting, shoeType, soleSize, weight) => {
    return Api.post('/api/cart/default/ski/add', '', { skiSKU, bindingSKU, mounting, shoeType, soleSize, weight });
  },
  addUlyssePackage: (skiSKU, bindingSKU, mounting, shoeType, soleSize, weight) => {
    return Api.post('/api/cart/default/ulysse/add', '', { skiSKU, bindingSKU, mounting, shoeType, soleSize, weight });
  }
};
