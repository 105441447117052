import Api from '@/Scripts/api/api';
import UrlHelper from '@/Scripts/lib/helper/urlHelper.js';

const state = {
  loadingCoupon: false,
  loadingCashpoints: false,
  cart: {
    CartItems: [],
    OrderSummary: {},
    estimatedArrival: 'Estimated arrival: Todo!'.length,
    loading: true
  },
  cashPoints: { AvailableCashpoints: {}, UseCashpoints: false },
  deliveryOption: {
    SelectedPickup: 'postOffice',
    SelectedPickupLocation: null,
    PickupLocations: []
  },
  payment: { PaymentOptions: [], SelectedPayment: '' },
  payPalMerchantId: '',
  paypalConfirmPaymentPage: '',
  vippsConfirmPaymentPage: '',
  currentCountry: '',
  homeDeliveryCost: 0,
  homeDeliveryItems: false,
  klarna_v3: false,
  disablePickupRadioButtons: false,
  shippingAddress: {
    FirstName: '',
    LastName: '',
    Address1: '',
    Address2: '',
    Postalcode: '',
    City: '',
    State: '',
    Email: '',
    Phone: ''
  },
  viewState: {
    showProductDetails: false,
    showPersonalCode: false,
    showPayment: false,
    showPickUpInStoreSection: false,
    showAddress: false,
    showKlarnaAddress: false,
    showPaypalAddress: false,
    showVippsAddress: false,
    showAddressSection: true,
    showPaymentSection: false,
    showCashpointSection: false,
    emptyState: false,
    featureHomeDelivery: false
  },
  errors: {
    invalidPersonalCode: false,
    requestFailed: false
  }
};

const getters = {
  cartCount () {
    let count = 0;

    state.cart.CartItems.forEach(element => {
      count = count + element.Quantity;
    });

    return count;
  },
  loyaltyPromotionCode: function () {
    if (state.cart.OrderSummary.LoyaltyPromotionCode) {
      return state.cart.OrderSummary.LoyaltyPromotionCode;
    } else {
      return null;
    }
  },
  loyaltyPromotionStatus: function () {
    if (state.cart.OrderSummary.LoyaltyPromotionStatus) {
      return state.cart.OrderSummary.LoyaltyPromotionStatus;
    } else {
      return null;
    }
  },
  loyaltyPointsForThisPurchase: function () {
    if (state.cart.OrderSummary.LoyaltyPointsForThisPurchase) {
      return state.cart.OrderSummary.LoyaltyPointsForThisPurchase;
    } else {
      return null;
    }
  },
  discountCode: function () {
    if (
      state.cart.OrderSummary.OrderDiscounts &&
      state.cart.OrderSummary.OrderDiscounts.length > 0 &&
      state.cart.OrderSummary.OrderDiscounts[0].DisplayName
    ) {
      return state.cart.OrderSummary.OrderDiscounts[0].DisplayName;
    } else {
      return '';
    }
  },
  discountValue: function () {
    if (
      state.cart.OrderSummary.OrderDiscounts &&
      state.cart.OrderSummary.OrderDiscounts.length > 0 &&
      state.cart.OrderSummary.OrderDiscounts[0].DiscountFormated
    ) {
      return state.cart.OrderSummary.OrderDiscounts[0].DiscountFormated;
    } else {
      return '';
    }
  },
  discountSavedAmount: function () {
    if (
      state.cart.OrderSummary.OrderDiscounts &&
      state.cart.OrderSummary.OrderDiscounts.length > 0 &&
      state.cart.OrderSummary.OrderDiscounts[0].PromotionInformation
    ) {
      return state.cart.OrderSummary.OrderDiscounts[0].PromotionInformation.SavedAmount;
    } else {
      return 0;
    }
  },
  discountType: function () {
    if (
      state.cart.OrderSummary.OrderDiscounts &&
      state.cart.OrderSummary.OrderDiscounts.length > 0 &&
      state.cart.OrderSummary.OrderDiscounts[0].Type >= 0
    ) {
      return state.cart.OrderSummary.OrderDiscounts[0].Type;
    } else {
      return 0;
    }
  },
  discountName: function () {
    if (
      state.cart.OrderSummary.OrderDiscounts &&
      state.cart.OrderSummary.OrderDiscounts.length > 0
    ) {
      return state.cart.OrderSummary.OrderDiscounts[0].DisplayName;
    } else {
      return '';
    }
  },
  discountPercentage: function () {
    if (
      state.cart.OrderSummary.OrderDiscounts &&
      state.cart.OrderSummary.OrderDiscounts.length > 0
    ) {
      return state.cart.OrderSummary.OrderDiscounts[0].Percentage;
    } else {
      return 0;
    }
  },
  validateAddress: function () {
    if (
      state.shippingAddress.FirstName &&
      state.shippingAddress.LastName &&
      state.shippingAddress.Address1 &&
      state.shippingAddress.Postalcode &&
      state.shippingAddress.City &&
      state.shippingAddress.Email &&
      state.shippingAddress.Phone
    ) {
      return true;
    } else {
      return false;
    }
  },
  getSelectedPickupLocation: function () {
    if (
      typeof state.deliveryOption.SelectedPickupLocation !== 'undefined' &&
      state.deliveryOption.SelectedPickupLocation !== null &&
      state.deliveryOption.PickupLocations
    ) {
      const found = state.deliveryOption.PickupLocations.find(element => element.No === state.deliveryOption.SelectedPickupLocation.No);
      return found;
    } else {
      return null;
    }
  }
};

const actions = {
  setSelectedPayment ({ commit }, value) {
    commit('setSelectedPayment', value);
  },
  getCheckoutItems ({ commit, rootState }) {
    Api.get(UrlHelper.getCheckoutApiUrl('', rootState.appContext.cartName))
      .then(response => {
        updateCheckoutState(commit, response.data);
      })
      .catch(err => {
        if (err.response.status !== 404) {
          throw err;
        } else {
          commit('setEmptyState', true);
          commit('showProductDetails', false);
          commit('setCartItems', []);
        }
      });

    if (document.body.getAttribute('data-is-loyalty') === 'True') {
      Api.get(UrlHelper.getCheckoutApiUrl('cashpoints', rootState.appContext.cartName))
        .then(response => {
          commit('setCashpoints', response.data);
          if (response.data.Points && response.data.Points > 0) {
            commit('showCashpointSection', true);
          }
        })
        .catch(err => {
          throw err;
        });
    }

    Api.get(UrlHelper.getCheckoutApiUrl('payment/options', rootState.appContext.cartName))
      .then(response => {
        commit('setPaymentOptions', response.data.options);

        let selectedPayment = '';

        if (response.data.options) {
          for (const elem of response.data.options) {
            if (elem.IsDefault) {
              selectedPayment = elem.SystemName;
              break;
            }

            // If no default set first
            selectedPayment = response.data.options[0].SystemName;
          }

          commit('setSelectedPayment', selectedPayment);

          commit('setShowAddressSection', true);
          commit('showAddress', false);
          commit('showKlarnaAddress', false);
          commit('showPaypalAddress', false);
          commit('showVippsAddress', false);

          if (selectedPayment === 'KLARNA') {
            commit('showKlarnaAddress', true);
          } else if (selectedPayment === 'PAYPAL') {
            commit('showPaypalAddress', true);
          } else if (selectedPayment === 'VIPPS') {
            commit('showVippsAddress', true);
          } else {
            commit('showAddress', true);
          }
        }
      })
      .catch(err => {
        throw err;
      });
  },
  getShippingCountryInfo ({ commit }) {
    const shippingCountryInfo = [];

    commit('setshippingCountryInfo', shippingCountryInfo);
  },
  quantityChange ({ commit, rootState }, obj) {
    // ShipmentId: -1: We are getting correct ShipmentId backend
    Api.post(UrlHelper.getCheckoutApiUrl('cartitem/change', rootState.appContext.cartName), '', { ShipmentId: -1, Code: obj.sku, Quantity: obj.quantity })
      .then(response => {
        updateCheckoutState(commit, response.data);
      })
      .catch(err => {
        throw err;
      });
  },
  addCoupon ({ commit, rootState }, value) {
    commit('setLoadingCoupon', true);
    commit('setInvalidCode', false);
    commit('setRequestFailed', false);
    commit('setOrderSummaryLoyaltyPromotionCode', null);
    commit('setOrderSummaryLoyaltyPromotionStatus', null);
    commit('setOrderSummaryLoyaltyPointsForThisPurchase', null);

    Api.post(UrlHelper.getCheckoutApiUrl('/coupon', rootState.appContext.cartName), '', { input: value })
      .then(response => {
        commit('setLoadingCoupon', false);
        updateCheckoutState(commit, response.data);
      })
      .catch(err => {
        commit('setLoadingCoupon', false);
        if (err.response.status === 400) {
          commit('setInvalidCode', true);
          return;
        } else {
          commit('setRequestFailed', true);
        }

        if (err.response.status !== 404) {
          throw err;
        }
      });
  },
  removeCoupon ({ commit, rootState }) {
    commit('setLoadingCoupon', true);

    Api.post(UrlHelper.getCheckoutApiUrl('coupon/remove', rootState.appContext.cartName), '', {
      input: state.cart.OrderSummary.OrderDiscounts[0].DisplayName
    })
      .then(response => {
        commit('setLoadingCoupon', false);
        updateCheckoutState(commit, response.data);
        commit('setInvalidCode', false);
        commit('setRequestFailed', false);
      })
      .catch(err => {
        if (err.response.status !== 404) {
          commit('setLoadingCoupon', false);
          throw err;
        }
      });
  },
  useCashpoints ({ commit, rootState }) {
    commit('setLoadingCashpoints', true);
    Api.post(UrlHelper.getCheckoutApiUrl('cashpoints', rootState.appContext.cartName), '', '')
      .then(response => {
        updateCheckoutState(commit, response.data);
        commit('setLoadingCashpoints', false);
      })
      .catch(err => {
        throw err;
      });
  },
  removeCashpoints ({ commit, rootState }) {
    commit('setLoadingCashpoints', true);

    Api.delete(UrlHelper.getCheckoutApiUrl('cashpoints', rootState.appContext.cartName), '', '')
      .then(response => {
        updateCheckoutState(commit, response.data);
        commit('setLoadingCashpoints', false);
      })
      .catch(err => {
        throw err;
      });
  },
  setPickupLocation ({ commit, rootState }, value) {
    commit('disablePickupRadioButtons', true);
    Api.post(UrlHelper.getCheckoutApiUrl('pickuplocation', rootState.appContext.cartName), '', { input: value })
      .then(response => {
        updateCheckoutState(commit, response.data);
        commit('disablePickupRadioButtons', false);
      })
      .catch(err => {
        throw err;
      });
  },
  updatePickupLocation ({ commit, rootState }, value) {
    Api.post(UrlHelper.getCheckoutApiUrl('updatepickuplocation', rootState.appContext.cartName), '', { input: value })
      .then(response => {
        commit('setOrderSummary', response.data.OrderSummary);
      })
      .catch(err => {
        throw err;
      });
  },
  removeCartItem ({ commit, dispatch, rootState }, sku) {
    Api.post(UrlHelper.getCartApiUrl('remove', rootState.appContext.cartName), '', { code: sku })
      .then(() => {
        dispatch('getCheckoutItems');
      })
      .catch(err => {
        throw err;
      });
  }
};

function updateCheckoutState (commit, data) {
  if (!data.CartItems || data.CartItems.length === 0) {
    commit('showProductDetails', false);
    commit('setEmptyState', true);
    commit('setCartItems', []);
  } else {
    commit('setEmptyState', false);
    commit('setCartItems', data.CartItems);
  }
  commit('setOrderSummary', data.OrderSummary);
  commit('showPaymentSection', data.OrderSummary.Total.Amount > 0);
  commit('setPickupLocations', data.PickupLocations);
  commit('setCurrentCountry', data.CurrentCountry);

  // Feature switch klarna_v3
  commit('klarna_v3', data.Klarna_v3);

  // Feature switch home delivery
  commit('featureHomeDelivery', data.FeatureHomeDelivery);
  // home delivery shipping cost
  commit('homeDeliveryCost', data.HomeDeliveryShippingCost);

  // Contains any service orders
  commit('homeDeliveryItems', data.HomeDeliveryItems);

  if (data.PickupLocations && data.PickupLocations.length > 0) {
    commit('setShowPickUpInStoreSection', true);
  } else {
    commit('setShowPickUpInStoreSection', false);
  }

  if (data.PickupLocations && data.SelectedPickupLocation && !data.HomeDelivery) {
    const selected = data.PickupLocations.filter(obj => {
      return obj.No === data.SelectedPickupLocation;
    });
    commit('setSelectedPickupLocation', selected[0]);
    commit('setDeliverySelectedPickup', 'store');
  } else if (data.HomeDelivery) {
    commit('setDeliverySelectedPickup', 'homeDelivery');
  } else {
    commit('setSelectedPickupLocation', null);
    commit('setDeliverySelectedPickup', 'postOffice');
  }

  if (
    data.OrderSummary &&
    data.OrderSummary.LoyaltyDiscount &&
    data.OrderSummary.LoyaltyDiscount.Discount
  ) {
    commit('setUseCashpoints', true);
  } else {
    commit('setUseCashpoints', false);
  }

  if (data.ShippingAddress) {
    commit('setShippingAddressFirstName', data.ShippingAddress.FirstName);
    commit('setShippingAddressLastName', data.ShippingAddress.LastName);
    commit('setShippingAddressAddress1', data.ShippingAddress.Line1);
    commit('setShippingAddressAddress2', data.ShippingAddress.Line2);
    commit('setShippingAddressPostalcode', data.ShippingAddress.PostalCode);
    commit('setShippingAddressCity', data.ShippingAddress.City);
    commit('setShippingAddressState', data.ShippingAddress.RegionCode);
    commit('setShippingAddressEmail', data.ShippingAddress.Email);
    commit('setShippingAddressPhone', data.ShippingAddress.DaytimePhoneNumber);
  }

  if (data.PayPalMerchantId) {
    commit('setPayPalMerchantId', data.PayPalMerchantId);
  } else {
    commit('setPayPalMerchantId', '');
  }

  if (data.PaypalConfirmPaymentPage) {
    commit('setPaypalConfirmPaymentPage', data.PaypalConfirmPaymentPage);
  } else {
    commit('setPaypalConfirmPaymentPage', '');
  }

  if (data.VippsConfirmPaymentPage) {
    commit('setVippsConfirmPaymentPage', data.VippsConfirmPaymentPage);
  } else {
    commit('setVippsConfirmPaymentPage', '');
  }
}

const mutations = {
  disablePickupRadioButtons (state, value) {
    state.disablePickupRadioButtons = value;
  },
  klarna_v3 (state, value) {
    state.klarna_v3 = value;
  },
  homeDeliveryCost (state, value) {
    state.homeDeliveryCost = value;
  },
  homeDeliveryItems (state, value) {
    state.homeDeliveryItems = value;
  },
  setPaypalConfirmPaymentPage (state, value) {
    state.paypalConfirmPaymentPage = value;
  },
  setVippsConfirmPaymentPage (state, value) {
    state.vippsConfirmPaymentPage = value;
  },
  setPayPalMerchantId (state, value) {
    state.payPalMerchantId = value;
  },
  setEmptyState (state, value) {
    state.viewState.emptyState = value;
  },
  showKlarnaAddress (state, value) {
    state.viewState.showKlarnaAddress = value;
  },
  showPaypalAddress (state, value) {
    state.viewState.showPaypalAddress = value;
  },
  showVippsAddress (state, value) {
    state.viewState.showVippsAddress = value;
  },
  setShowAddressSection (state, value) {
    state.viewState.showAddressSection = value;
  },
  setPaymentOptions (state, value) {
    state.payment.PaymentOptions = value;
  },
  setShowPickUpInStoreSection (state, value) {
    state.viewState.showPickUpInStoreSection = value;
  },
  setCurrentCountry (state, value) {
    state.currentCountry = value;
  },
  setSelectedPickupLocation (state, value) {
    state.deliveryOption.SelectedPickupLocation = value;
  },
  setPickupLocations (state, value) {
    state.deliveryOption.PickupLocations = value;
  },
  setCashpoints (state, value) {
    state.cashPoints.AvailableCashpoints = value;
  },
  setRequestFailed (state, value) {
    state.errors.requestFailed = value;
  },
  setInvalidCode (state, value) {
    state.errors.invalidPersonalCode = value;
  },
  setCartItems (state, cartItem) {
    state.cart.CartItems = cartItem;
    state.cart.loading = false;
  },
  setOrderSummary (state, orderSummary) {
    state.cart.OrderSummary = orderSummary;
  },
  setOrderSummaryLoyaltyPromotionCode (state, value) {
    state.cart.OrderSummary.LoyaltyPromotionCode = value;
  },
  setOrderSummaryLoyaltyPromotionStatus (state, value) {
    state.cart.OrderSummary.LoyaltyPromotionStatus = value;
  },
  setOrderSummaryLoyaltyPointsForThisPurchase (state, value) {
    state.cart.OrderSummary.LoyaltyPointsForThisPurchase = value;
  },
  showPaymentSection (state, value) {
    state.viewState.showPaymentSection = value;
  },
  showCashpointSection (state, value) {
    state.viewState.showCashpointSection = value;
  },
  showProductDetails (state, value) {
    state.viewState.showProductDetails = value;
  },
  showPersonalCode (state, value) {
    state.viewState.showPersonalCode = value;
  },
  showPayment (state, value) {
    state.viewState.showPayment = value;
  },
  showAddress (state, value) {
    state.viewState.showAddress = value;
  },
  featureHomeDelivery (state, value) {
    state.viewState.featureHomeDelivery = value;
  },
  setUseCashpoints (state, value) {
    state.cashPoints.UseCashpoints = value;
  },
  setDiscountCode (state, value) {
    state.discountCode.Code = value;
  },
  setDiscountPerentage (state, value) {
    state.discountCode.DiscountPerentage = value;
  },
  setEstimatedArrival (state, value) {
    state.estimatedArrival = value;
  },
  setDeliverySelectedPickup (state, value) {
    state.deliveryOption.SelectedPickup = value;
  },
  setDeliverySelectedStore (state, value) {
    state.deliveryOption.SelectedStore = value;
  },
  setDeliveryStores (state, value) {
    state.deliveryOption.Stores = value;
  },
  setSelectedPayment (state, value) {
    state.payment.SelectedPayment = value;
  },
  setShippingAddressFirstName (state, value) {
    state.shippingAddress.FirstName = value;
  },
  setShippingAddressLastName (state, value) {
    state.shippingAddress.LastName = value;
  },
  setShippingAddressAddress1 (state, value) {
    state.shippingAddress.Address1 = value;
  },
  setShippingAddressAddress2 (state, value) {
    state.shippingAddress.Address2 = value;
  },
  setShippingAddressPostalcode (state, value) {
    state.shippingAddress.Postalcode = value;
  },
  setShippingAddressCity (state, value) {
    state.shippingAddress.City = value;
  },
  setShippingAddressState (state, value) {
    state.shippingAddress.State = value;
  },
  setShippingAddressEmail (state, value) {
    state.shippingAddress.Email = value;
  },
  setShippingAddressPhone (state, value) {
    state.shippingAddress.Phone = value;
  },
  setLoadingCoupon (state, value) {
    state.loadingCoupon = value;
  },
  setLoadingCashpoints (state, value) {
    state.loadingCashpoints = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
