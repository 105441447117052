/* Store for SiteInfo */
import Api from '@/Scripts/api/api';

const state = {
  initialized: false,
  links: '',
  notification: '',
  countries: [],
  googleApiKey: '',
  usp: {
    EstimatedArrivalText: '',
    FirstMiddleArticle: {},
    InformationBlock: {},
    PaymentMethods: [],
    SecondMiddleArticle: {},
    UspReturnsPage: '',
    UspShippingPage: '',
    UspWarrantyPage: ''
  }
};

const actions = {
  getSiteInformation ({ commit }) {
    if (!state.initialized) {
      commit('setInitialized', true);
      Api.get('/api/siteinfo')
        .then(response => {
          // SiteInfo
          let links = {};
          // console.log(window.menuData, window.menuData.Links);
          if (window.menuData && window.menuData.Links) {
            links = { ...response.data.Links, ...window.menuData.Links };
          } else {
            links = response.data.Links;
          }

          commit('setLinks', links);
          commit('setNotification', response.data.Notification);
          commit('setCountries', response.data.Countries);
          commit('setUsp', response.data.Usp);
          commit('setGoogleApiKey', response.data.GoogleApiKey);
        })
        .catch(function (error) {
          commit('setInitialized', false);
          console.error('Unable to receive siteinfo data:', error);
        });
    }
  }
};

const mutations = {
  setInitialized (state, value) {
    state.initialized = value;
  },
  setLinks (state, value) {
    state.links = value;
  },
  setNotification (state, value) {
    state.notification = value;
  },
  setCountries (state, value) {
    state.countries = value;
  },
  setUsp (state, value) {
    state.usp = value;
  },
  setGoogleApiKey (state, value) {
    state.googleApiKey = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
