
/**
 * The main vuex store. This holds the state of the URL and makes sure that
 * when the URL is updated, the model gets updated too.
 */

import Vue from 'vue';
import Vuex from 'vuex';

// the module handling model state
import epiDataModel from '@/Scripts/store/modules/epiDataModel';

// the module handling episerver specific state
import epiContext from '@/Scripts/store/modules/epiContext';

// the module handling app specific state
import appContext from '@/Scripts/store/modules/appContext';

import userInformation from '@/Scripts/store/modules/userInformation';
import toaster from '@/Scripts/store/modules/toaster';
import siteInfo from '@/Scripts/store/modules/siteInfo';

import productList from '@/Scripts/store/modules/productList';
import checkout from '@/Scripts/store/modules/checkout';
import checkoutNew from '@/Scripts/store/modules/checkoutNew';
import bag from '@/Scripts/store/modules/bag';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    productList,
    checkout,
    checkoutNew,
    appContext,
    epiDataModel,
    epiContext,
    userInformation,
    toaster,
    siteInfo,
    bag

  }
});

const lazyModules = [
  // {
  //   // loaded on b2c
  //   name: 'bag',
  //   module: () => import(/* webpackChunkName: "storeBag" */ '@/Scripts/store/modules/bag')
  // },
  // {
  //   // loaded on productList
  //   name: 'productList',
  //   module: () => import(/* webpackChunkName: "storeProductList" */ '@/Scripts/store/modules/productList')
  // },
  // {
  //   // loaded on checkout, orderconfirmation
  //   name: 'checkout',
  //   module: () => import(/* webpackChunkName: "storeCheckout" */ '@/Scripts/store/modules/checkout')
  // },
  // {
  //   // loaded on checkout, orderconfirmation
  //   name: 'checkoutNew',
  //   module: () => import(/* webpackChunkName: "storeCheckout" */ '@/Scripts/store/modules/checkoutNew')
  // },
  {
    // loaded on AccountInformation.vue and LoyaltyRegistration.vue
    name: 'consent',
    module: () => import(/* webpackChunkName: "storeConsent" */ '@/Scripts/store/modules/consent')
  },
  {
    // loaded on Mypage
    name: 'countries',
    module: () => import(/* webpackChunkName: "storeContries" */ '@/Scripts/store/modules/countries')
  },
  {
    // b2b customer
    name: 'b2bCustomer',
    module: () => import(/* webpackChunkName: "storeB2BCustomer" */ '@/Scripts/store/modules/b2bCustomer')
  }

];

function registerStoreModule (moduleName, callback) {
  const moduleExists = typeof store.state[moduleName] !== 'undefined';
  if (moduleExists) {
    if (typeof callback === 'function') {
      callback();
    }
  } else {
    const newModule = lazyModules.find(obj => obj.name === moduleName);
    if (newModule) {
      newModule.module().then((module) => {
        console.log('vuex module ' + moduleName + ' added');
        store.registerModule(moduleName, module.default);
        store.dispatch('appContext/addLazyModules', moduleName);
        if (typeof callback === 'function') {
          callback();
        }
      });
    } else {
      console.warn('vuex module ' + moduleName + ' not found');
    }
  }
}

export {
  store,
  registerStoreModule
};
