/**
 * [loaded globally]
 * @file Handling translation
 *
 *
 * @author Torbjørn Høvde
 * @version 1.0
 *
 * @example
 *
 * insertString('Orders', 'Header');
 */

export default {
  methods: {
    insertString (...keys) {
      if (typeof window.jsl10n !== 'undefined') {
        const obj = window.jsl10n;
        const key = keys.join('.').toLocaleLowerCase();
        const translation = obj[key];
        if (translation) {
          return translation;
        } else {
          console.warn('Missing translation for', key);
          return '';
        }
      } else {
        console.warn('Cannot connect to translations');
        return '';
      }
    },
    replaceAndInsert (replaceStr, replaceValue, ...keys) {
      const str = this.insertString(...keys);
      return str.replace(`{${replaceStr}}`, replaceValue);
    },
    replaceMultipleAndInsert (replaceArr, ...keys) {
      let str = this.insertString(...keys);
      for (let i = 0; i < replaceArr.length; i++) {
        str = str.replace(`{${replaceArr[i].str}}`, replaceArr[i].value);
      }
      return str;
    }
  }
};
