import '@/Scripts/public-path';
import Vue from 'vue';
import '@/scss/main.scss';

import pages from '@/Scripts/lib/config/pages.js';

import { addMediaQueries } from '@/Scripts/lib/config/mediaqueries.js';
import translation from '@/Scripts/lib/helper/translation.js';
import colors from '@/Scripts/lib/helper/colorHelper.js';
import detectBrowser from '@/Scripts/lib/helper/detectBrowser.js';

import { tabEventListener } from '@/Scripts/lib/helper/userTypeHelper.js';
import detectDevice from '@/Scripts/lib/helper/deviceTypeHelper.js';
import { VueHammer } from 'vue2-hammer'; // for swip/touch events

import checkView from 'vue-check-view'; // element in view event
import vueCookies from 'vue-cookies'; // cookies
import { TrackerIdentify } from '@/Scripts/lib/helper/trackingHelper.js';

import ieMessage from '@/Scripts/lib/helper/ieMessage.js';
import PortalVue from 'portal-vue';
import ABtest from '@/Scripts/lib/mixins/abtest.js';

// eslint-disable-next-line no-unused-vars
import Prototypes from '@/Scripts/lib/helper/prototypes.js';

if (document.documentMode !== undefined) {
  document.body.outerHTML = ieMessage.innerHTML;
  window.stop();
}

Object.entries = Object.entries || (x => Object.keys(x).map(k => [k, x[k]]));
Vue.use(VueHammer);
Vue.use(PortalVue);
Vue.use(checkView);
Vue.use(vueCookies); // in use as '$cookie' object
Vue.mixin(ABtest);
Vue.mixin(translation);
Vue.mixin(colors);
Vue.mixin(detectBrowser);

Vue.config.ignoredElements = ['stream'];

detectDevice();
tabEventListener();
addMediaQueries();

pages.registerAll();
// blocks.registerBlocks(true); // true = lazy loading of blocks

// Check for no-touchdevice
if (!('ontouchstart' in document.documentElement)) {
  document.documentElement.className += ' no-touch';
}

TrackerIdentify();
