import Api from '@/Scripts/api/api';

const splitSku = (sku) => {
  const parts = sku.split(' ');
  const model = parts[0];
  const color = parts[1];
  const size = parts.length === 4 ? `${parts[2]} ${parts[3]}` : parts[2];
  return {
    model,
    color,
    size
  };
};

export default {
  getWishlist (id) {
    return Api.get('/api/wishlist/GetWishlist', { id });
  },
  getWishlists () {
    return Api.get('/api/wishlist/GetWishlists');
  },
  // isOnWishlist: (sku, name) => {
  //   const p = splitSku(sku);
  //   return Api.get('/api/wishlist/IsOnWishlist', { model: p.model, color: p.color, size: p.size, name });
  // },
  removeWishlistItem: (listId, itemId) => {
    return Api.delete('/api/wishlist/RemoveItem', { id: listId, itemId: itemId });
  },
  addWishlistItem: (listId, sku) => {
    // string id, string model, string color = null, string size = null, int quantity = 1
    const p = splitSku(sku);
    if (listId && p && p.model && p.color && p.size) {
      return Api.post('/api/wishlist/AddItem', { id: listId, ...p });
    } else {
      return new Promise((resolve, reject) => {
        const err = `missing data, current data is:  product: ${p}, list: ${listId}`;
        console.log(err);
        setTimeout(() => reject(new Error(err)), 1000);
      });
    }
  },
  updateWishlistItem: (listId, itemId, product) => {
    if (listId && itemId && product) {
      return Api.patch('/api/wishlist/UpdateItem', { id: listId, itemId: itemId, model: product.model, color: product.color, size: product.size, comment: product.comment });
    } else {
      return new Promise((resolve, reject) => {
        const err = `missing data, current data is:  product: ${product}, list: ${listId}`;
        console.log(err);
        setTimeout(() => reject(new Error(err)), 1000);
      });
    }
  },

  // Default     = 0, // an actual wishlist
  // B2BSalesRep = 1, // used for b2b where sales rep can make a "suggestion"
  // Outfit      = 2, // Maybe for outfit guide so f.ex Jørgen can make a wishlist that will be shown in the style of outfit guide
  // CX          = 3, // CX can make a wishlist for a user
  // Store       = 4, // A store can make a wishlist for a user
  // Notify      = 5, // A type to notify for back in stock

  addWishlist (name, type = 0) {
    return Api.post('/api/wishlist/Create', null, { name, WishlistType: type });
  },
  deleteWishlist (id) {
    return Api.post('/api/wishlist/Delete', null, { id });
  },
  renameWishlist (id, name) {
    return Api.post('/api/wishlist/UpdateName', null, { id, name });
  },
  reorderWishlist (id, items) {
    return Api.post('/api/wishlist/UpdateSortOrder', null, { id, items: items });
  },
  shareWishlist (id) {
    return Api.post('/api/wishlist/UpdatePrivacy', null, { id, privacy: 1 });
  },
  unShareWishlist (id) {
    return Api.post('/api/wishlist/UpdatePrivacy', null, { id, privacy: 0 });
  }

};
