const checkoutPath = '/api/checkout';
const cartPath = '/api/cart';
export default {
  getCurrentUrlWithAction (action, keepOldParams = true) {
    let url = window.location.origin;

    if (window.location.pathname === '/') {
      url = `${url}/${document.documentElement.lang}/`;
    } else {
      url = `${url}/${window.location.pathname}`;
    }

    if (!url.endsWith('/')) {
      url = `${url}/`;
    }

    url = `${url}${action}`;

    if (keepOldParams) {
      return `${url}${window.location.search}`;
    }

    return `${url}`;
  },
  getCheckoutApiUrl (action = '', cart = 'default') {
    return `${checkoutPath}/${cart}/${action}`;
  },
  getCartApiUrl (action = '', cart = 'default') {
    return `${cartPath}/${cart}/${action}`;
  }

};
