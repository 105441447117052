/**
 * Wraps the calls to the ContentDeliveryAPI. It's used by the vuex
 * `epiDataModel` module and the `ArtistContainerPage` component.
 */

import axios from 'axios';

const call = (method, url, baseURL, parameters, headers, data, cancelToken) => {
  if (headers === null || typeof headers === 'undefined') {
    headers = {};
  }

  headers['Accept-Language'] = document.documentElement.lang;

  return axios({
    method: method,
    baseURL: baseURL,
    url: url,
    params: parameters,
    headers: Object.assign({}, headers),
    data: data,
    cancelToken: cancelToken
  });
};

const baseContentApiURL = '/api/episerver/v2.0/';
const callContentAPI = (method, url, parameters, headers) => call(method, url, baseContentApiURL, parameters, headers);
const callRegularRouting = (method, url, parameters, data, cancelToken) => call(method, url, '/', parameters, { Accept: 'application/json' }, data, cancelToken);
export default {
  /**
     * Getting content with the content link is the default way of calling the ContentDeliveryAPI.
     * It is used in MusicFestival to get:
     *  - block data
     *  - updated data after a `beta/contentSaved` message, which has the content link
     */
  getContentByContentLink: (contentLink, parameters, headers) => callContentAPI('get', `content/${contentLink}`, parameters, headers),

  /**
     * Getting data from ContentDeliveryAPI through regular routing (friendly
     * URLs) is enabled by the extensions in Infrastructure/ContentDeliveryExtendedRouting.
     * It is used in MusicFestival to get:
     *  - page data, through the vuex `epiDataModel` module
     */
  getContentByFriendlyUrlWithCancel: (friendlyUrl, parameters, cancelToken) => {
    const data = {};
    return call('get', friendlyUrl, '/', parameters, { Accept: 'application/json' }, data, cancelToken);
  },
  getContentByFriendlyUrl: (friendlyUrl, parameters) => callRegularRouting('get', friendlyUrl, parameters),
  postContentByFriendlyUrl: (friendlyUrl, data) => callRegularRouting('post', friendlyUrl, null, data),

  /**
     * Doing the same as getContentByFriendlyUrl but adds user language to the api call
     */
  get: (url, parameters, data) => {
    const userLang = document.documentElement.lang;
    return callRegularRouting('get', userLang + url, parameters, data);
  },

  /**
     * Posting data using user language to the api call
     */
  post: (url, parameters, data) => {
    const userLang = document.documentElement.lang;
    return callRegularRouting('post', userLang + url, parameters, data);
  },

  put: (url, parameters, data) => {
    const userLang = document.documentElement.lang;
    return callRegularRouting('put', userLang + url, parameters, data);
  },
  putNoLanguage: (url, parameters, data, cancelToken) => {
    return callRegularRouting('put', url, parameters, data, cancelToken);
  },

  patch: (url, parameters, data) => {
    const userLang = document.documentElement.lang;
    return callRegularRouting('patch', userLang + url, parameters, data);
  },
  postNoLanguage: (url, parameters, data, cancelToken) => {
    return callRegularRouting('post', url, parameters, data, cancelToken);
  },
  postFormData: (url, formData) => {
    const headers = {};
    headers['Accept-Language'] = document.documentElement.lang;
    headers.Accept = 'multipart/form-data';

    return axios({
      method: 'post',
      baseURL: '/',
      url: url,
      body: formData,
      headers: Object.assign({}, headers)
    });
  },

  postNoLanguageWithAntiForgeryToken: (url, parameters, data, token) => {
    return call('post', url, '/', parameters, { Accept: 'application/json', __RequestVerificationToken: token }, data);
  },

  /**
     * Deleting data using user language to the api call
     */
  delete: (url, parameters, data) => {
    const userLang = document.documentElement.lang;
    return callRegularRouting('delete', userLang + url, parameters, data);
  },
  deleteNoLanguage: (url, parameters, data) => {
    return callRegularRouting('delete', url, parameters, data);
  },

  /**
     * Getting the children of the page with ContentDeliveryAPI is enabled by
     * the extensions in Infrastructure/ContentDeliveryExtendedRouting.
     * It is used in MusicFestival to get:
     *  - artist list in ArtistContainerPage.vue
     */
  getChildren: (friendlyUrl, parameters) => {
    // Split URL into path and queries
    const urlPieces = friendlyUrl.split('?');
    // In View mode we might visit the URL with or without a trailing / (i.e. "http://localhost:56312/en/artists" or "http://localhost:56312/en/artists/")
    const pathname = (urlPieces[0].endsWith('/') ? urlPieces[0] : urlPieces[0] + '/');
    // In Edit mode we'll have URL queries (i.e. "/EPiServer/CMS/Content/en/artists,,6/?epieditmode=True")
    const queries = urlPieces[1] ? '?' + urlPieces[1] : '';

    // Concatenate the friendly URL with "/children" for the Content API
    const callUrl = pathname + 'children' + queries;

    return callRegularRouting('get', callUrl, parameters);
  }
};
