/* Store for Toaster */

const state = {
  list: []
};
const getters = {};
const actions = {
  addToast ({ commit }, toast) {
    if (!Object.prototype.hasOwnProperty.call(toast, 'id')) {
      toast.id = Math.random() * 100000000;
    }
    commit('pushToList', toast);
  },
  removeToast ({ commit }, index) {
    commit('spliceList', index);
  },
  removeAllToasts ({ commit }, index) {
    commit('emptyList', index);
  }
};

const mutations = {
  pushToList: (state, toast) => {
    state.list.push(toast);
  },
  spliceList: (state, index) => {
    state.list.splice(index, 1);
  },
  emptyList: (state, index) => {
    state.list = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
