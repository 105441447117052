/**
 * @file Handling translation
 *
 *
 * @author Jaklin Hristova
 * @version 1.0
 *
 * @example
 *
 */

export default {
  data () {
    return {
      Globals: {
        colors: {
          black: '#1D1D1D',
          blackLight: '#2C2F32',
          primary: '#1D1D1D',
          secondary: '#F5F5F5',
          green: '#00FF99',
          white: '#FCFCFC',
          gray: '#BEBEBE',
          grayLight: '#DADADA',
          grayLighter: '#F5F5F5',
          grayDark: '#747474',
          red: '#D13E3E',
          redLight: '#E7807B',
          greenLight: '#00FF99',
          greenDark: '#349F74',
          blueLight: '#4fcaff',
          blueDark: '#006BFF',
          yellow: '#fcff87'
        }
      }
    };
  }
};
