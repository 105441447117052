import Api from '@/Scripts/api/api';
import axios from 'axios';

import stringHelper from '@/Scripts/lib/helper/stringHelper.js';

const productPageSizeDefault = 12;
const productPageSizeUnlimited = 500;
let cancelSource;

// This is the state variables
const state = {
  products: [],
  productGroupings: [],
  header: {},
  englishName: '',
  facets: [],
  productsLoading: false,
  productPage: 1,
  unlimitedPageSize: false,
  productPageSize: productPageSizeDefault,
  productGroupingPage: [],
  allLoaded: false,
  totalMatching: 0,
  listViewMode: '',
  selectedColors: [],
  selectedSizes: [],
  sortOptions: [],
  performanceSort: ['', '', ''],
  areaOfUseFilters: [],
  performanceRatingInfo: [],
  areaOfUseGroups: []
};

const getters = {
  // Gets array of selected filters.
  getURLFilterParams: state => {
    const filterParams = [];
    state.facets.forEach(facet => {
      const grp = { key: facet.FieldName, filters: [] };
      const filters = facet.Terms.filter(term => term.Selected);
      if (filters && filters.length > 0) {
        filters.forEach(val => {
          grp.filters.push(val.FieldName);
        });
      }
      filterParams.push(grp);
    });
    return filterParams;
  }
};

const actions = {
  // Gets the first productlist page from the API.
  // Default size is 12 products
  async getProductList ({ commit }, payLoad = { pages: 1, size: state.productPageSize }) {
    let pages = payLoad.pages;
    let size = payLoad.size;

    // remove last call
    if (cancelSource) {
      cancelSource.cancel();
    }
    // create new cancel token
    cancelSource = axios.CancelToken.source();
    if (state.productPageSize === productPageSizeUnlimited) {
      pages = 1;
      size = productPageSizeUnlimited;
    }
    commit('setProductsLoading', true);
    commit('setProductPage', 1);
    commit('setProducts', []);
    commit('setProductPageSize', size);
    for (let i = 0; i < pages; i++) {
      const url = getUrl('json', state.productPage + i, size);
      // console.log(url);
      try {
        const response = await Api.getContentByFriendlyUrlWithCancel(url, {}, cancelSource.token);
        if (i === 0) {
          commit('setProducts', response.data.Products);
          commit('setProductGroupings', response.data.ProductGroupings);
          commit('setHeader', response.data.CurrentContent);
          commit('setEnglishName', response.data.EnglishName);
          commit('setFacets', response.data.Facets);
          commit('setSortOptions', response.data.SortOptions);
          commit('setAreaOfUseGroups', response.data.AreaOfUseGroups);
          commit('setTotalMatching', response.data.TotalMatching);
          // commit('setProductsLoading', false);
        } else {
          commit('concatProducts', response.data.Products);
        }
      } catch (error) {
        console.log(error);
      };
    }

    commit('setProductPage', pages);
    commit('setProductsLoading', false);
  },
  // Gets the next productlist page from the API.
  // Default size is 12 products
  loadMoreProducts ({ commit }) {
    if (!state.allLoaded && !state.productsLoading) {
      commit('setProductsLoading', true);
      const url = getUrl('json', state.productPage + 1, state.productPageSize);
      // console.log(url);
      Api.getContentByFriendlyUrl(url).then(response => {
        if (response.data.Products.length > 0) {
          commit('setProductPage', state.productPage + 1);
          commit('concatProducts', response.data.Products);
          commit('setFacets', response.data.Facets);
        } else {
          commit('setAllLoaded', true);
        }

        commit('setProductsLoading', false);
      });
    }
  },
  async loadMoreGroupedProducts ({ commit }, payLoad) {
    if (!state.productGroupings[payLoad.index].AllLoaded && (!state.productGroupings[payLoad.index].firstLoad || typeof state.productGroupings[payLoad.index].firstLoad === 'undefined')) {
      state.productGroupings[payLoad.index].firstLoad = payLoad.firstload;
      if (typeof state.productGroupings[payLoad.index].Page === 'undefined') {
        commit('updateProductGroupingsPage', { index: payLoad.index, page: 2 });
      }

      const startpage = state.productGroupings[payLoad.index].Page;
      for (let i = startpage; i <= payLoad.page; i++) {
        let url = stringHelper.updateUrlParameter(payLoad.url, 'page', i);
        url = stringHelper.updateUrlParameter(url, 'size', payLoad.size);
        // console.log('lazy: ', url);
        try {
          const response = await Api.getContentByFriendlyUrlWithCancel(url);
          if (typeof response.data.products !== 'undefined') {
            const finish = response.data.products.length === 0;
            commit('updateProductGroupings', { products: response.data.products, index: payLoad.index, page: payLoad.page + 1, finish: finish });
            if (i === payLoad.page) {
              state.productGroupings[payLoad.index].firstLoad = false;
            }
          }
        } catch (error) {
          console.log(error);
        };
      }
    }
  },
  // sets the filter selected
  changeFilter ({ commit }, payLoad) {
    commit('setAllLoaded', false);
    commit('setFilterSelected', payLoad);
  },
  changeViewMode ({ commit }, mode) {
    commit('setViewMode', mode);
  },
  updatePerformanceSort ({ commit }, arr) {
    commit('setPerformanceSort', arr);
  },
  updateAreaOfUseFilters ({ commit }, arr) {
    commit('setAreaOfUseFilters', arr);
  },
  updatePerformanceRatingInfo ({ commit }, arr) {
    commit('setPerformanceRatingInfo', arr);
  },
  changeProductPageSize ({ commit }, size) {
    commit('setAllLoaded', false);
    commit('setProductPageSize', size);
  }

};

// Mutations is responsible for updating the state. Should only happen here
const mutations = {
  // Replaces the products state
  setProducts (state, products) {
    state.products = products;
  },
  setProductGroupings (state, groups) {
    state.productGroupings = groups;
  },
  clearProductGroupingsAllList (state) {
    state.productGroupingsAllListLoaded = false;
    state.productGroupingsAllList = [];
  },
  updateProductGroupings (state, payload) {
    if (!payload.finish) {
      payload.products.forEach(item => {
        if (state.productGroupings[payload.index]) {
          state.productGroupings[payload.index].Products.push(item);
          state.productGroupings[payload.index].Page = payload.page;
          state.productGroupings[payload.index].AllLoaded = false;
        } else {
          console.warn('state.productGroupings: index doesnt exist', payload.index);
        }
      });
    } else {
      state.productGroupings[payload.index].AllLoaded = true;
      // console.log('state.productGroupings: all loaded', payload.index);
    }
  },
  updateProductGroupingsPage (state, payload) {
    state.productGroupings[payload.index].Page = payload.page;
  },

  setHeader (state, header) {
    state.header = header;
  },
  setEnglishName (state, eName) {
    state.englishName = eName;
  },

  // Merging products in existing state
  concatProducts (state, products) {
    state.products = state.products.concat(products);
  },
  // Replaces the filter state
  setFacets (state, facets) {
    state.facets = facets;
    state.selectedColors = [];
    state.selectedSizes = [];
    if (typeof facets !== 'undefined') {
      const colorFacet = facets.find(facet => facet.FieldName === 'color');
      if (colorFacet !== null && typeof colorFacet !== 'undefined') {
        colorFacet.Terms.forEach((item) => {
          if (item.Selected) {
            state.selectedColors.push(item.FieldName);
          }
        });
      }

      const sizeFacet = facets.find(facet => facet.FieldName === 'displaysize');
      if (sizeFacet !== null && typeof sizeFacet !== 'undefined') {
        sizeFacet.Terms.forEach((item) => {
          if (item.Selected) {
            state.selectedSizes.push(item.FieldName);
          }
        });
      }
    }
  },
  setSortOptions (state, options) {
    state.sortOptions = options;
  },
  // Replaces the total matching products number
  setTotalMatching (state, total) {
    state.totalMatching = total;
  },
  // Replaces the product loading state
  setProductsLoading (state, productsLoading) {
    state.productsLoading = productsLoading;
  },
  // Replaces the current productPage.
  // Used for getting the next page from API
  setProductPage (state, nextPage) {
    state.productPage = nextPage;
  },
  setAllLoaded (state, loaded) {
    state.allLoaded = loaded;
  },
  // Replaces the selected filters in the state
  setFilterSelected (state, payLoad) {
    const facet = state.facets.find(facet => facet.FieldName === payLoad.facetName);
    if (facet) {
      if (payLoad.clearOthers) {
        facet.Terms.forEach(term => {
          term.Selected = false;
        });
      }
      const term = facet.Terms.find(term => term.FieldName === payLoad.termName);
      if (term) {
        term.Selected = payLoad.selected;
      }
    } else {
      console.warn(`${payLoad.facetName} not found`);
    }
  },
  // Clears all selected filters in the state
  clearFilterSelected (state) {
    state.facets.forEach(facet => {
      facet.Terms.filter(term => term.Selected).forEach(termSelected => {
        termSelected.Selected = false;
      });
    });
  },
  setViewMode (state, mode) {
    state.listViewMode = mode;
  },
  setPerformanceSort (state, arr) {
    state.performanceSort = arr;
  },
  setAreaOfUseFilters (state, arr) {
    state.areaOfUseFilters = arr;
  },
  setPerformanceRatingInfo (state, arr) {
    state.performanceRatingInfo = arr;
  },
  setAreaOfUseGroups (state, arr) {
    state.areaOfUseGroups = arr;
  },
  setProductPageSize (state, size) {
    if (size === 'default') {
      size = productPageSizeDefault;
      state.unlimitedPageSize = false;
    } else if (size === 'unlimited') {
      size = productPageSizeUnlimited;
      state.unlimitedPageSize = true;
    }
    state.productPageSize = size;
  }

};

// Private methode to get the api url for the productlist.
// Handle filter, paging and sorting
function getUrl (key, page, size) {
  let url = `${window.location.pathname.replace(/\/$/, '')}/${key}`;
  const paramsFromURL = window.location.search;
  if (paramsFromURL) {
    // Removes ? from params since we allready have added it
    url = `${url}?${paramsFromURL.slice(1)}`;
  }

  if (page) {
    url = stringHelper.updateUrlParameter(url, 'page', page);
  }
  if (size) {
    url = stringHelper.updateUrlParameter(url, 'size', size);
  }

  return url;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
