import cartHelper from '@/Scripts/lib/helper/cartHelper';
import wishlistHelper from '@/Scripts/lib/helper/wishlistHelper';
import { WISHLIST_TYPES } from '@/Scripts/constants.js';

function findLatestWishlistDate (list) {
  return new Date(Math.max(...list.Items.map(i => new Date(i.CreatedDate))));
};

function compareWishlists (a, b) {
  const aLatestDate = findLatestWishlistDate(a);
  const bLatestDate = findLatestWishlistDate(b);
  if (aLatestDate < bLatestDate) {
    return 1;
  }
  if (aLatestDate > bLatestDate) {
    return -1;
  }
  return 0;
};

const state = {
  cart: {
    Shipments: [],
    Total: {},
    ShippingCost: {}
  },
  wishlistLoader: false,
  wishlists: [],
  wishlistItems: [],
  wishlistProducts: []
};

const getters = {
  cartItems: state => {
    const items = [];
    state.cart.Shipments.forEach(obj => {
      obj.CartItems.forEach(item => {
        items.push(item);
      });
    });
    return items;
  },
  cartCount () {
    let count = 0;
    state.cart.Shipments.forEach(obj => {
      obj.CartItems.forEach(item => {
        count += item.Quantity;
      });
    });

    return count;
  },

  calculateShipping: state => {
    if (!state.cart.ShippingCost.Amount) {
      return window.jsl10n['Global.Free'];
    } else {
      return `${state.cart.ShippingCost.CurrencyCode} ${state.cart.ShippingCost.Amount.toLocaleString('en').replace(',', ' ')}`;
    }
  },
  calculateTotal: state => {
    if (!state.cart.Total.Amount) {
      return `${state.cart.Total.CurrencyCode} 0`;
    }
    return `${state.cart.Total.CurrencyCode} ${state.cart.Total.Amount.toLocaleString('en').replace(',', ' ')}`;
  },
  calculateTotalExclShipping: state => {
    if (!state.cart.Total.Amount) {
      return `${state.cart.Total.CurrencyCode} 0`;
    }

    return `${state.cart.Total.CurrencyCode} ${(state.cart.Total.Amount - state.cart.ShippingCost.Amount).toLocaleString('en').replace(',', ' ')}`;
  }
};

const actions = {
  getCartItems ({ commit }) {
    cartHelper.getBagItems('default')
      .then(response => {
        if (response.data) {
          commit('setCartItems', response.data);
        } else {
          commit('setCartItems',
            {
              Shipments: [],
              Total: {},
              ShippingCost: {}
            });
        }
      })
      .catch(err => {
        if (err.response.status !== 404) {
          throw err;
        }
      });
  },
  removeCartItem ({ commit }, sku) {
    cartHelper.removeBagItem('default', sku).then(response => {
      commit('setCartItems', response.data);
    });
  },
  addCartItems ({ commit }, payload) {
    // payload = {sku, listName}
    cartHelper.addBagItem('default', payload.sku, payload.listName).then(response => {
      commit('setCartItems', response.data);
    });
  },
  removeCartMagazine ({ commit, dispatch }, sku) {
    cartHelper.removeCartMagazine('default', sku).then(response => {
      dispatch('bag/getCartItems', {}, { root: true });
    });
  },
  changeCartMagazine ({ commit, dispatch }, payload) {
    cartHelper.addCartMagazine('default', payload.sku).then(response => {
      cartHelper.removeCartMagazine('default', payload.previouslySelectedMagazineSku).then(response => {
        dispatch('bag/getCartItems', {}, { root: true });
      });
    });
  },
  addCartMagazine ({ commit, dispatch }, sku) {
    cartHelper.addCartMagazine('default', sku).then(response => {
      dispatch('bag/getCartItems', {}, { root: true });
    });
  },
  getWishlist ({ commit, rootState }, email) {
    // if email exist = logged in
    if (email) {
      commit('setWishlistLoader', true);

      wishlistHelper.getWishlists().then(response => {
        const items = [];
        const products = [];
        response.data.Wishlists.forEach((list) => {
          if (list.WishlistType !== WISHLIST_TYPES.OUTFIT) {
            items.push(...list.Items);
            products.push(...list.Products);
          }
        });
        commit('setWishlistItems', [...new Set(items)]); // push only unique
        commit('setWishlistProducts', [...new Set(products)]); // push only unique
        commit('setWishlists', response.data.Wishlists);
        commit('setWishlistLoader', false);
      }).catch(err => {
        if (err && err.response && err.response.status !== 404) {
          commit('setWishlistLoader', false);
          throw err;
        }
      });
    } else {
      console.warn('Wishlist: Not logged in. email:', email);
    }
  },
  getWishlistById ({ commit, rootState }, id) {
    // if email exist = logged in
    if (id) {
      commit('setWishlistLoader', true);

      wishlistHelper.getWishlist(id).then(response => {
        const items = [];
        const products = [];
        const list = response.data;
        items.push(list.Items);
        products.push(list.Products);
        commit('setWishlistItems', [...new Set(items)]); // push only unique
        commit('setWishlistProducts', [...new Set(products)]); // push only unique
        commit('setWishlists', [list]);
        commit('setWishlistLoader', false);
      }).catch(err => {
        if (err && err.response && err.response.status !== 404) {
          commit('setWishlistLoader', false);
          throw err;
        }
      });
    } else {
      console.warn('Wishlist: Wrong id. id:', id);
    }
  }

};

const mutations = {
  setCartItems (state, cartItem) {
    state.cart = cartItem;
  },
  setWishlistLoader (state, data) {
    state.wishlistLoader = data;
  },
  setWishlistItems (state, data) {
    state.wishlistItems = data;
  },
  setWishlistProducts (state, data) {
    state.wishlistProducts = data;
  },
  setWishlists (state, data) {
    if (data) {
      state.wishlists = [...data].sort(compareWishlists);
    } else {
      state.wishlists = [];
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
