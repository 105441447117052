/**
 * @file .
 *
 *
 * @author Jostein Kirkebak
 * @version 1.0
 *
 * @example
 */

export default {
  data () {
    return {
      Globals: {
        isTouchDevice: ('ontouchstart' in document.documentElement)
      }
    };
  }
};
