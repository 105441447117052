import Vue from 'vue';
import FineMq from 'fine-mq';
import { BREAKPOINTS } from '@/Scripts/constants';

export function addMediaQueries () {
  Vue.use(FineMq, {
    aliases: {
      xs: BREAKPOINTS.SM - 1,
      sm: [BREAKPOINTS.SM, BREAKPOINTS.MD - 1],
      md: [BREAKPOINTS.MD, BREAKPOINTS.LG - 1],
      lg: [BREAKPOINTS.LG, BREAKPOINTS.XL - 1],
      xl: [BREAKPOINTS.XL],
      xxl: [BREAKPOINTS.XXL],
      mobile: [0, BREAKPOINTS.LG - 1],
      desktop: [BREAKPOINTS.LG],
      desktopLarge: [BREAKPOINTS.XL],
      tablet: [BREAKPOINTS.MD]
    }
  });
}
